import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/modules/security/models/user';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {
  sub: any;
  key: string;
  utilisateur: User;

  constructor(private logService: LogService, private authService: AuthService,
              private route: ActivatedRoute, private router: Router
    ,         private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.route.params.forEach(params => {
      this.logService.log('Activation key : ' + params[`key`]);
      this.key = params[`key`];

      if (this.key != null) {
        this.authService.activate(this.key).subscribe(utilisateur => {
          if (utilisateur && utilisateur.active) {
            this.utilisateur = utilisateur;

            this.notificationService.successMessage('Votre adresse mail a été vérifiée, un administrateur va vous autoriser');
            this.notificationService.infoMessage('Veuillez vous connecter!');

          } else {
            // something went wrong
            this.utilisateur = new User;
            this.notificationService.errorMessage('Votre compte n\'est pas activé', null);

          }

        });
      }

    });
  }

}
