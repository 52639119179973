import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class LogService {
  constructor(private notificationService: NotificationService) {}

  log(message: any) {
    if (!environment.production) {
      console.log(message);
    }
  }

  error(objet: any) {
    // console.log("#####LOG ERROR#####");
    // console.log(objet.split(',')[0]);
    // console.log("#####LOG ERROR#####");

    // if (objet ) {
    //   this.notificationService.serverError("Erreur Système : " + objet.split(',')[0]);
    // }
    if (!environment.production) {
      console.error(objet);
    } else {
      //  A ecrire dans un fichier
    }
  }
}
