import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonCompteComponent } from './components/mon-compte/mon-compte.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CreerCompteComponent } from './components/creer-compte/creer-compte.component';
import { Router, RouterModule } from '@angular/router';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ActivationComponent } from './components/activation/activation.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { RecoveryComponent } from './components/forget-password/recovery/recovery.component';
import { CaptchaModule } from 'primeng/captcha';
import { DividerModule } from 'primeng/divider';
import { AvatarModule } from 'primeng/avatar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    MonCompteComponent,
    ResetPasswordComponent,
    CreerCompteComponent,
    ForgetPasswordComponent,
    ActivationComponent,
    RecoveryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),
    TranslateModule.forRoot(
      {
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }
  ),
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    PasswordModule,
    ToastModule,
    CaptchaModule,
    DividerModule,
    AvatarModule


  ]
})
export class CompteModule { }
