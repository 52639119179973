import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { ObjectExtensionsComponent } from "src/app/modules/setting/components/extension/object-extensions/object-extensions.component";
import { ListItem } from "src/app/modules/setting/models/codification";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import {
  CategorieDemande,
  Demande,
  TypeDemandeMontant,
} from "../../models/demande";
import { DemandeConstants, ExtensionSurvatterConstants, TypeDemandeMontantConstants, TypeServiceConstants } from "../../models/demande-constants";
import { DemandeHabilitation } from "../../models/habilitation/demande-habilitation";
import { Formulaire } from "../../models/formulaire";
import { PersonnePhysique } from "../../models/personne";
import { CategorieDemandeService } from "../../services/categorie-demande.service";
import { DemandeSoumissionService } from "../../services/demande-soumission.service";
import { DemandeService } from "../../services/demande.service";
import { FormulaireService } from "../../services/formulaire.service";
import { PersonnePhysiqueService } from "../../services/personne/personne-physique.service";
import { TypeDemandeService } from "../../services/type-demande.service";
import { TypeDemandeMontantService } from "../../services/type-demande-montant.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { ValeurExtension } from "src/app/modules/setting/models/extension";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-formulaire",
  templateUrl: "./formulaire.component.html",
  styleUrls: ["./formulaire.component.css"],
})
export class FormulaireComponent implements OnInit, AfterViewInit {
  @Input() demandeId: string;
  // @Input() groupeExtension: string;
  @Input() categorieDemande: string;
  @Input() typeService: string;
  @Output() afterSave: EventEmitter<any> = new EventEmitter();

  items: MenuItem[];
  position = 1;

  concept: string;
  object: string;
  editable: boolean;

  saveButtonLabel: string = "Enregistrer";
  categorieDemandes: ListItem[];

  typeDemandeMontants: TypeDemandeMontant[];
  typeDemandeMontant: string;

  demande: Demande;
  demandeur: PersonnePhysique;

  submitted: boolean;

  formulaire: Formulaire;

  dateDebut: Date;
  dateFin: Date;
  diffMonths: number = 0;
  typeDemandeMontantReal: string;

  // @ViewChild("ext") extComponent: ObjectExtensionsComponent;
  @ViewChildren(ObjectExtensionsComponent)
  extComponents: QueryList<ObjectExtensionsComponent>;

  constructor(
    public messageService: MessageService,
    public demandeSoumissionService: DemandeSoumissionService,
    private router: Router,
    private route: ActivatedRoute,
    private logService: LogService,
    public habilitationService: DemandeHabilitation,
    private demandeService: DemandeService,
    private breadcrumbService: BreadcrumbService,
    private personnePhysiqueService: PersonnePhysiqueService,
    private categorieDemandeService: CategorieDemandeService,
    private notificationService: NotificationService,
    private typeDemandeMontantService: TypeDemandeMontantService,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    this.concept = DemandeConstants.CONCEPT_METIER_DEMANDE;
    // this.groupeExtension = DemandeConstants.GROUPE_EXTENSION_SURVATTER;

    this.demande = {} as Demande;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {

      this.initTypeService(params.ts);
      this.demandeId = params.id;
      if (this.demandeId != null) {
        this.breadcrumbService.setItems([
          // { label: this.demande.categorieDemande.typeDemande.libelle },
          { label: this.translate.instant('DEMANDE.FORMULAIRE.REQUEST') },
          { label: this.translate.instant('DEMANDE.FORMULAIRE.FORM') },
        ]);
      } else {
        this.editable = true;
        this.saveButtonLabel = this.translate.instant('DEMANDE.FORMULAIRE.NEXT');
      }
      this.initParameters();
    });
  }

  ngAfterViewInit(): void {
  }

  initSurvatterFormValues() {
    this.logService.log("initSurvatterFormValues START");
    if (!this.demandeId) {
      this.logService.log(this.extComponents);
      this.extComponents.map((extComponent) => {
        this.logService.log(
          "extComponent START***********************************"
        );
        this.logService.log(extComponent);
        this.logService.log(
          "extComponent START***********************************"
        );
      });

      this.extComponents.toArray().forEach((extComponent) => {
        this.logService.log(extComponent);
        this.logService.log("initSurvatterFormValues END");
        // for (var i in extComponent.valeurExtensions) {

        //   if (
        //     extComponent.valeurExtensions[i].extension.id == "SURVATTER_APPL"
        //   ) {
        //     this.personnePhysiqueService
        //       .find(this.authService.getUserPersonneId())
        //       .subscribe((data) => {
        //         if (data) {
        //           let personne = data as PersonnePhysique;
        //           this.logService.log(personne);
        //           this.logService.log("initSurvatterFormValues");

        //           extComponent.valeurExtensions[i].valeur =
        //             personne.nom +
        //             " " +
        //             personne.prenoms +
        //             ", " +
        //             personne.adresse;
        //         }
        //       });
        //   }
        // }
      });
    }
  }

  ngOnChanges() {
    this.initParameters();
  }

  initParameters() {
    this.initTypeService(this.typeService);
    if (this.demandeId != null) {
      this.demandeService.find(this.demandeId).subscribe((data) => {
        if (!data || !data.id) {
          this.habilitationService.redirectHome();
        }
        this.demande = data;
        this.categorieDemande = this.demande.categorieDemande.id;
        this.typeDemandeMontant = this.demande.typeDemandeMontant.id;

        this.initTypeService(
          this.demande.categorieDemande.typeDemande.typeService.id
        );
        this.object = this.demande.id;
        this.editable = this.demande.etat.modifiable;
        // this.extComponent.setObject(this.demande.id);

        this.personnePhysiqueService
          .find(this.demande.personne.id)
          .subscribe((p) => {
            this.demandeur = p;
          });

        // this.extComponent.setObject(this.demande.id);
        // this.extComponent.updateValeurExtensions();
      });
    } else {
      this.personnePhysiqueService
        .find(this.habilitationService.userPersonneId())
        .subscribe((p) => {
          this.demandeur = p;
        });
      this.object = null;
      this.editable = true;
    }

  }

  initTypeService(ts: string) {
    if (!ts) {
      ts = TypeServiceConstants.SURVATTER;
    }

    if (ts) {
      this.typeService = ts;
      this.categorieDemandeService
        .getAll(this.typeService, null)
        .subscribe((data) => {
          this.categorieDemandes = data;
          if (this.categorieDemandes && this.categorieDemandes.length == 1) {
            this.categorieDemande = this.categorieDemandes[0].id;
          }
          this.selectCategorie();
        });
    }
  }

  selectCategorie() {
    if (this.categorieDemande) {
      this.categorieDemandeService
        .formulaire(this.categorieDemande)
        .subscribe((fm) => {
          this.logService.log(fm);
          this.formulaire = fm;
        });

      this.typeDemandeMontantService
        .getAllByCategorie(this.categorieDemande)
        .subscribe((data) => {
          this.logService.log("typeDemandeMontants");
          this.logService.log(data);

          this.typeDemandeMontants = data;
        });
    }
  }

  valeurChange(vext: ValeurExtension) {
    if (!this.dateDebut || !this.dateFin) {
      this.initSurvatterMontant();
    }

    this.logService.log("valeurChange dateDebut " + this.dateDebut);
    this.logService.log("valeurChange dateFin " + this.dateFin);

    this.logService.log(vext);
    if (vext.extension?.id == ExtensionSurvatterConstants.SURVATTER_DEPDT && vext.valeurDate != null) {
      this.dateDebut = new Date(vext.valeurDate);
    }
    if (vext.extension?.id == ExtensionSurvatterConstants.SURVATTER_ARRDT && vext.valeurDate != null) {
      this.dateFin = new Date(vext.valeurDate);
    }

    this.checkSurvatterMontant();

    this.logService.log("valeurChange");
  }

  checkSurvatterMontant(): boolean {

    this.logService.log("checkSurvatterMontant dateDebut " + this.dateDebut);
    this.logService.log("checkSurvatterMontant dateFin " + this.dateFin);

    if (this.dateDebut && this.dateFin) {
      // To calculate the no. of months between two dates
      this.diffMonths = this.diff_months(this.dateFin, this.dateDebut);
      this.logService.log("diff_months " + this.dateDebut + " - " + this.dateFin + " = " + this.diffMonths + " mois")

      this.typeDemandeMontantReal = TypeDemandeMontantConstants.SURVATTER_0;

      if (this.diffMonths > 0) {
        this.typeDemandeMontantReal = (this.diffMonths <= 3) ? TypeDemandeMontantConstants.SURVATTER_0_3
          : ((this.diffMonths <= 6) ? TypeDemandeMontantConstants.SURVATTER_3_6 : TypeDemandeMontantConstants.SURVATTER_6_12)
      }

      if (this.typeDemandeMontantReal != this.typeDemandeMontant) {
        this.notificationService.warningMessage("Le délai de validité l'attestation serait de " + this.diffMonths + " Mois");
        this.typeDemandeMontant = this.typeDemandeMontantReal;
      }
    }

    return true;

  }

  initSurvatterMontant() {
    if (!this.extComponents) {
      return;
    }

    this.typeDemandeMontantReal = this.typeDemandeMontant;

    this.logService.log("detectSurvatterMontant START");
    let valeurExts: ValeurExtension[][] = this.extComponents.map((extComponent) => {
      // extComponent.updateValeurExtensions();
      return extComponent.valeurExtensions;
    });

    this.logService.log(valeurExts);
    this.logService.log("detectSurvatterMontant END");

    valeurExts.forEach(vexts => {
      let value = vexts.find(vext => vext.extension.id == ExtensionSurvatterConstants.SURVATTER_DEPDT)?.valeur;
      if (value) this.dateDebut = new Date(value);
      value = vexts.find(vext => vext.extension.id == ExtensionSurvatterConstants.SURVATTER_ARRDT)?.valeur;
      if (value) this.dateFin = new Date(value);
    });

  }

  diff_days(dt2: Date, dt1: Date): number {
    var diff = (dt2.getTime() - dt1.getTime()) / (1000 * 3600 * 24);
    return Math.abs(Math.round(diff));
  }

  diff_months(dt2: Date, dt1: Date): number {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7 * 4);
    return Math.abs(Math.round(diff));

  }



  nextPage() {

    this.checkSurvatterMontant();


    this.logService.log("FormulaireComponent - START");

    // this.router.navigate([DemandeConstants.ROUTE_DEMANDE_CREATE + '/pieces']);
    if (!this.editable) {
      this.router.navigateByUrl(
        DemandeConstants.ROUTE_DEMANDE_CREATE_PIECES + "?id=" + this.demande.id
      );
    } else {
      if (!this.typeDemandeMontant) {
        this.notificationService.errorMessage(
          this.translate.instant('DEMANDE.FORMULAIRE.ERROR'),
          this.translate.instant('DEMANDE.FORMULAIRE.ERRORMSG')
        );
        return;
      }
      let formvalid = true;
      this.logService.log(this.extComponents);
      if (this.extComponents && this.extComponents.length > 0) {
        this.extComponents.forEach((extComponent) => {
          if (!extComponent.checkValeurExtensions()) {
            formvalid = false;
          }
          if (!extComponent.checkSurvatterValeurExtensions()) {
            formvalid = false;
          }
        });
      }

      if (!formvalid) {
        return;
      }
      // if (!this.extComponent.checkValeurExtensions()) {
      //   return;
      // }

      this.demande.categorieDemande = {} as CategorieDemande;
      this.demande.categorieDemande.id = this.categorieDemande;
      this.demande.typeDemandeMontant = {} as TypeDemandeMontant;
      this.demande.typeDemandeMontant.id = this.typeDemandeMontant;
      this.demande.personne = {} as PersonnePhysique;
      this.demande.personne.id = this.habilitationService.userPersonneId();

      this.demandeService.save(this.demande).subscribe((data) => {
        if (data && data.id) {
          this.demande = data;
          this.demandeSoumissionService.demande = this.demande;

          // this.extComponent.setObject(this.demande.id);
          let savedInfos: boolean = true;
          if (this.extComponents && this.extComponents.length > 0) {
            this.extComponents.forEach((extComponent) => {
              savedInfos =
                savedInfos &&
                extComponent.saveValeurExtensions(this.demande.id);
            });
          }
          //  savedInfos = this.extComponent.saveValeurExtensions(
          //   this.demande.id
          // );

          if (savedInfos) {
            //   this.demandeSoumissionService.demandeInformation.demande = this.demande;
            this.logService.log("FormulaireComponent - END");

            this.router.navigateByUrl(
              DemandeConstants.ROUTE_DEMANDE_CREATE_PIECES +
              "?id=" +
              this.demande.id
            );
          }
        }
      });
    }
  }
}
