import { HabilitationService } from 'src/app/modules/shared/services/habilitation.service';
import { DemandeConstants, ProfilUtilisteur } from '../demande-constants';

export class DemandeHabilitation extends HabilitationService {
	getFunction(): string {
		return DemandeConstants.FUNCTION_DEMANDE;
	}

	
	canRead(): boolean {
		return super.canRead() && 
		this.hasProfil(ProfilUtilisteur.DEMANDEUR);
	  }

}
