import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CrudService } from 'src/app/modules/shared/services/crud.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { environment } from 'src/environments/environment';
import { Extension, ValeurExtension } from '../../models/extension';


const URL_EXTENSION: string = environment.apiUrl + '/setting/extension';
@Injectable({
  providedIn: 'root'
})
export class ExtensionService extends CrudService {

  ressourceBaseURL(): string {
    return URL_EXTENSION;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }

  findAllByGroupe(idGrpe: string): Observable<Extension[]> {
    return this.httpClient.get<any>(this.ressourceBaseURL() + '/' + idGrpe + '/list'
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

  getValeurExtension(objId: string, idExt: string): Observable<ValeurExtension> {
    return this.httpClient.get<any>(this.ressourceBaseURL() + '/valeur/' + objId
      + '/object/' + idExt, {}
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

  listValeurExtensions(objId: string, gpExt: string): Observable<ValeurExtension[]> {
    return this.httpClient.post<any>(this.ressourceBaseURL() + '/valeur/' + objId
      + '/object?idgExt=' + gpExt, {}
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

  saveValeurExtensions(objId: string, vext: ValeurExtension[]): Observable<boolean> {
    return this.httpClient.post<any>(this.ressourceBaseURL() + '/save/valeur/'
      + objId + '/object', vext
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }
}
