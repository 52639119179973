import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";
import { PieceRequis } from "../models/piece";

const URL_PIECE_REQUIS: string = environment.gasAPIUrl + "/piece-requis";

@Injectable({
  providedIn: "root",
})
export class PieceRequisService extends CrudService {
  ressourceBaseURL(): string {
    return URL_PIECE_REQUIS;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }

  listByCategorieDmde(
    idCatDmde: string,
    required?: boolean
  ): Observable<PieceRequis[]> {
    let url = this.ressourceBaseURL() + "/list/" + idCatDmde + "/categorie";
    if (required != null) {
      url = url + "?required=" + required;
    }
    return this.httpClient.get<any>(url).pipe(
      catchError((error: any): Observable<any> => {
        this.logService.error(error);
        return of(null);
      })
    );
  }
}
