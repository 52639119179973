import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache: Map<String, any> = new Map();

  // In seconde
  private retentionInterval: number = 3600;
  private lastUpdate: Date;


  constructor(private logService: LogService) {
    if (environment.cacheRetentionInterval) {
      this.retentionInterval = environment.cacheRetentionInterval;
    }
    this.reset();
  }

  has(key: string): boolean {
    if (this.isTimeout()) this.reset();
    return !this.isTimeout() && this.cache.has(key);
  }

  set(key: string, response: any) {
    this.lastUpdate = new Date();
    this.cache.set(key, response);
  }

  get(key: string) {
    return (this.has(key)) ? this.cache.get(key) : null;
  }

  reset() {
    this.cache.clear();
    this.lastUpdate = null;
  }
  
  private isTimeout(): boolean {
    if (!this.lastUpdate) {
      return true;
    }
    let timeout = this.diffSeconds(new Date(), this.lastUpdate) - this.retentionInterval;
    this.logService.log(this.lastUpdate + " cache timeout: " + timeout);
    return timeout > 0;
  }

  

  private diffSeconds(dt2: Date, dt1: Date): number {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.abs(Math.round(diff));
  }
}
