import { Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MessageLevel } from '../models/helpers';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private messageService: MessageService, private confirmationService: ConfirmationService) { }

  message(summary: string, message: string, level: MessageLevel) {
    this.messageService.add({
      severity: level,
      summary,
      detail: message,
      life: 5000,
    });
  }

  serverError(message?: string) {
    this.errorMessage(message, null);
  }

  errorMessage(summary?: string, message?: string) {
    if (summary == null) {
      summary = 'Une erreur est survenue';
    }
    if (message == null) {
      message = 'Veuillez conctacter l\'administrateur!';
    }
    this.message(summary, message, MessageLevel.ERROR);
  }

  warningMessage(summary: string, message?: string) {
    if (message == null) {
      message = 'Veuillez conctacter l\'administrateur!';
    }
    this.message(summary, message, MessageLevel.WARNING);
  }

  infoMessage(summary: string, message?: string) {
    this.message(summary, message, MessageLevel.INFO);
  }

  successMessage(message?: string) {
    if (message == null) {
      message = '';
    }
    this.message('Opération réussie!', message, MessageLevel.INFO);
  }

  deleteConfirm(acceptCallback: Function) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected products?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => acceptCallback
    });
  }

}
