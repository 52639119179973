import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accueil-content',
  templateUrl: './accueil-content.component.html',
  styleUrls: ['./accueil-content.component.css']
})
export class AccueilContentComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
  }
  

}
