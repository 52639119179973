import { HabilitationService } from 'src/app/modules/shared/services/habilitation.service';

export class HomeConstants{
	static ROUTE_HOME = '/accueil';
	static ROUTE_APROPOS = '/accueil/aprpos';
	static ROUTE_LOGIN = '/login';
	static ROUTE_NOT_FOUND = '/notfound';
	static ROUTE_ACCESS_DENIED = '/accessdenied';

}

export enum Language {
	Francais = "Francais",
	English = "English",
  }