import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ListItem } from "src/app/modules/setting/models/codification";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { HomeConstants } from "../../../home/models/home-constants";
import { Demande, Feeback } from "../../models/demande";
import { DemandeConstants } from "../../models/demande-constants";
import { DemandeService } from "../../services/demande.service";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.css"],
})
export class FeedbackComponent implements OnInit {
  documents: { name: string }[];
  // cities : City[];
  demande: Demande;
  feedback: Feeback;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private route: ActivatedRoute,
    private demandeService: DemandeService,
    private notificationService: NotificationService
  ) {}

  selectedCategory: any = null;
  editable: boolean;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        this.demandeService.find(params.id).subscribe((dmde) => {
          if (dmde && dmde.documentLivre) {
            this.demande = dmde;
            this.demande.documentLivre.dateDelivrance = new Date(
              dmde.documentLivre.dateDelivrance
            );

            this.breadcrumbService.setItems([
              { label: "Demandes" },
              { label: this.demande.reference },
              { label: "Formulaire d'enquête" },
            ]);

            if (this.demande.feedback && this.demande.feedback.id) {
              this.feedback = this.demande.feedback;
            } else {
              this.feedback = {} as Feeback;
              this.feedback.demande = this.demande;
              this.feedback.contenuAppreciation = {} as ListItem;
              this.feedback.delaiAppreciation = {} as ListItem;
              this.feedback.infoAppreciation = {} as ListItem;
              this.feedback.reponseAppreciation = {} as ListItem;
              this.feedback.appreciationGlobale = {} as ListItem;
            }
            this.editable = this.feedback.id == null;
          }
        });
      } else {
        this.router.navigateByUrl(HomeConstants.ROUTE_NOT_FOUND);
      }
    });

    this.documents = [
      { name: "Demande SURVATTER" },
      { name: "Licence PEL " },
      { name: "etc." },
    ];
  }

  saveFeedback() {
    if (
      !this.feedback.contenuAppreciation.id ||
      !this.feedback.reponseAppreciation.id ||
      !this.feedback.delaiAppreciation.id ||
      !this.feedback.infoAppreciation.id ||
      !this.feedback.appreciationGlobale.id
    ) {
      this.notificationService.warningMessage(
        "Veuillez nous donner votre satifaction sur chaque sujet et globalement!",
        ""
      );
      return;
    }
    this.demandeService
      .saveFeedback(this.demande.id, this.feedback)
      .subscribe((fb) => {
        this.feedback = fb;
        this.editable = this.feedback.id == null;
        this.notificationService.successMessage("Merci pour votre feedback!");
      });
  }
}
