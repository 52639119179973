import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";
import { TypeDemandeMontant } from "../models/demande";

const URL_TYPE_DEMANDE_MONTANT: string =
  environment.gasAPIUrl + "/demande/montant";
@Injectable({
  providedIn: "root",
})
export class TypeDemandeMontantService extends CrudService {
  ressourceBaseURL(): string {
    return URL_TYPE_DEMANDE_MONTANT;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }

  getAll(typeDemande: string): Observable<TypeDemandeMontant[]> {
    let url = this.ressourceBaseURL() + "/" + typeDemande + "/typeDemande";
    return this.httpClient.get<TypeDemandeMontant[]>(url).pipe(
      catchError((error: any): Observable<TypeDemandeMontant[]> => {
        this.logService.error(error);
        return of(null);
      })
    );
  }

  
  getAllByCategorie(categorie: string): Observable<TypeDemandeMontant[]> {
    let url = this.ressourceBaseURL() + "/" + categorie + "/categorie";
    return this.httpClient.get<TypeDemandeMontant[]>(url).pipe(
      catchError((error: any): Observable<TypeDemandeMontant[]> => {
        this.logService.error(error);
        return of(null);
      })
    );
  }
}
