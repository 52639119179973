import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BreadcrumbService } from 'src/app/modules/shared/components/breadcrumb.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';
import { Demande } from '../../models/demande';
import { DemandeConstants } from '../../models/demande-constants';
import { DemandeHabilitation } from '../../models/habilitation/demande-habilitation';
import { FileContentType, PieceJointe } from '../../models/piece';
import { DemandeSoumissionService } from '../../services/demande-soumission.service';
import { DemandeService } from '../../services/demande.service';
import { PieceJointeService } from '../../services/piece-jointe.service';
import { PieceRequisService } from '../../services/piece-requis.service';

@Component({
  selector: 'app-pieces-jointes',
  templateUrl: './pieces-jointes.component.html',
  styleUrls: ['./pieces-jointes.component.css'],
  providers: [MessageService],
})
export class PiecesJointesComponent implements OnInit {
  demande: Demande;
  pieces: PieceJointe[];
  selectedPiece: PieceJointe;

  @ViewChild("dt") table: Table;
  currentPage: number=0;
  first:number= 0;

  uploadedFiles: any[] = [];
  checked = false;

  src: string;
  concept: string;
  userId: string;
  fonction: string;

  constructor(
    private notificationService: NotificationService,
    private demandeSoumissionService: DemandeSoumissionService,
    private pieceRequisService: PieceRequisService,
    private pieceJointeService: PieceJointeService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private habilitationService: DemandeHabilitation,
    private demandeService: DemandeService,
    private logService: LogService
  ) {
    this.concept = DemandeConstants.CONCEPT_METIER_PIECEJONTE;
    this.fonction = DemandeConstants.FUNCTION_DEMANDE;
    this.userId = this.habilitationService.userId();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const id = params.id;
      if (id != null) {
        this.demandeService.find(id).subscribe((data) => {
          if (!data || !data.id) {
            this.habilitationService.redirectHome();
          }

          this.demande = data;

          this.breadcrumbService.setItems([
            {
              label: 'Demandes',
            },
            { label: this.demande.categorieDemande.typeDemande.libelle },
            { label: this.demande.reference },
            { label: 'Pieces jointes' },
          ]);

          this.demandeSoumissionService.setDemande(data);
          this.getDemandePieceJointes();
        });
      } else {
        this.getDemandePieceJointes();
      }
    });
  }

  getDemandePieceJointes() {
    this.demande = this.demandeSoumissionService.getDemande();

    if (this.demande && this.demande.id) {
      this.pieces = [] as PieceJointe[];

      this.pieceJointeService.listByDmde(this.demande.id, true).subscribe((data) => {
        if (data && data.length > 0) {
          this.pieces = data;
        }
        this.pieceRequisService
          .listByCategorieDmde(
            DemandeConstants.CATEGORIE_DELIVRANCE_SURVATTER,
            true
          )
          .subscribe((pieceRequises) => {
            if (pieceRequises) {
              pieceRequises.forEach((pr) => {
                if (!this.filterByPieceRequis(pr.id)) {
                  const pj: PieceJointe = {} as PieceJointe;
                  pj.demande = this.demande;
                  pj.pieceRequis = pr;
                  pj.valide = false;
                  this.pieces.push(pj);
                }
              });
              this.pieces.sort((a,b)=>(a.pieceRequis.code > b.pieceRequis.code) ? 1 :
             ((b.pieceRequis.code > a.pieceRequis.code) ? -1 : 0));
            }
          });
      });
    } else {
      this.notificationService.errorMessage('Demande non trouvée');
    }
  }

  resetTable() {
    this.table.reset();
    this.table.first= this.first>0? 0: this.table.totalRecords+1;
    setTimeout(() => this.table.first=this.first, 100);
  }

  
  onPage(event: LazyLoadEvent){
    this.logService.log("OnPage");
    this.logService.log(event)
    this.first= event.first;
    this.currentPage = event.first / event.rows;
    this.logService.log("OnPage - "+ this.currentPage);
  }


  filterByPieceRequis(idPieceRequise: string): boolean {
    let checked = false;
    if (this.pieces && this.pieces.length > 0) {
      this.pieces.forEach((p) => {
        if (p.pieceRequis.id == idPieceRequise) {
          checked = true;
        }
      });
    }
    return checked;
  }

  canNextPage(): boolean {
    const checked = true;
    if (this.demande == null || this.demande.id == null) {
      return false;
    }
    // if (this.pieces && this.pieces.length > 0) {
    //   this.pieces.forEach((pj) => {
    //     if (pj.document == null || pj.document.id == null) {
    //       checked = false;
    //       this.notificationService.errorMessage(
    //         pj.pieceRequis.piece.libelle,
    //         "Veuillez charger cette pièce!"
    //       );
    //     } else if (!pj.conform) {
    //       this.notificationService.warningMessage(
    //         pj.pieceRequis.piece.libelle,
    //         "Veuillez attestez que le fichier est conforme en cochant la case en dessous!"
    //       );
    //       checked = false;
    //     }
    //   });
    // }

    return checked;
  }
  nextPage() {
    if (this.canNextPage()) {
      this.demandeSoumissionService.pieces = this.pieces;
      this.demandeSoumissionService.demande = this.demande;

      this.router.navigateByUrl(
        DemandeConstants.ROUTE_DEMANDE_CREATE_PAIEMENT +
          '?id=' +
          this.demande.id
      );
    }
    // if (this.seatInformation.class && this.seatInformation.seat && this.seatInformation.wagon) {
    //     this.ticketService.ticketInformation.seatInformation = this.seatInformation;
    // }
  }

  prevPage() {
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_CREATE_INFOS + '?id=' + this.demande.id
    );
  }

  openWindow(fichier) {
    fichier.click();
  }

  isPieceSubmitted(piece: PieceJointe): boolean {
    return piece != null && piece.id != null && piece.document != null;
  }

  onRowSelect(event) {
    this.selectedPiece.conform = this.selectedPiece.id != null;
    this.setViewURL();
  }

  setViewURL() {
    if (this.selectedPiece) {
      this.src =
        this.pieceJointeService.ressourceBaseURL() +
        '/download/' +
        this.selectedPiece.id;
    }
  }

  acceptUploadedFile() {

    this.pieces.forEach((pj) => {
      if (pj.id == this.selectedPiece.id) {
        pj.conform = this.selectedPiece.conform;
      }
    });
  }

  upload(piece: PieceJointe) {
    this.pieceJointeService
      .upload(this.demande.id, piece.pieceRequis.id, piece.file)
      .subscribe((data) => {
        this.pieces.forEach((p) => {
          if (p.pieceRequis.id == data.pieceRequis.id) {
            p.id = data.id;
            p.document = data.document;
            this.notificationService.infoMessage(
              'Pièce \'' + p.pieceRequis.libelle + '\' Soumise '
              // + p.file.name
            );
            this.selectedPiece = p;
            this.setViewURL();
            this.resetTable();
          }
        });
      });
  }

  onSelect(event, piece: PieceJointe) {
    for (const file of event.files) {
      if (!this.isImage(file.type) && !this.isPDFDocument(file.type)) {
        this.notificationService.errorMessage(
          'Format PDF ou image accepté',
          null
        );
        break;
      }
      piece.file = file;

      this.upload(piece);
      // this.uploadedFiles.push(file);
    }

    // this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
  }

  isImage(type: string) {
    return type.includes(FileContentType.IMAGE);
  }
  isPDFDocument(type: string) {
    return type.includes(FileContentType.PDF);
  }
}
