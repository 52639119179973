import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Demande } from '../models/demande';
import { PieceJointe } from '../models/piece';

@Injectable()
export class DemandeSoumissionService {
    demande: Demande;

    pieces: PieceJointe[];

    paiement: any;


    private paymentComplete = new Subject<any>();

    paymentComplete$ = this.paymentComplete.asObservable();

    getDemande() {
        return this.demande;
    }
    setDemande(demande) {
        this.demande = demande;
    }

    getPieces() {
        return this.pieces;
    }
    setPieces(pieces: PieceJointe[]) {
        this.pieces = pieces;
    }

    getPaiement() {
        return this.paiement;
    }
    setPaiement(paiement) {
        this.paiement = paiement;
    }


    complete() {
        this.paymentComplete.next(this.demande);
    }
}
