import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { MegaMenuItem, MenuItem } from "primeng/api";
import { Subscription } from "rxjs";
import { ActionNotification } from "src/app/modules/setting/models/workflow";
import { AppMainComponent } from "src/app/modules/shared/components/app.main.component";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { DemandeConstants } from "../../../demande/models/demande-constants";
import { DemandeHabilitation } from "../../../demande/models/habilitation/demande-habilitation";
import { HomeConstants, Language } from "../../models/home-constants";
import { HomeHabilitation } from "../../models/home-habilitation";
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: "app-accueil",
  templateUrl: "./accueil.component.html",
  styleUrls: ["./accueil.component.css"],
  encapsulation: ViewEncapsulation.None,
})



export class AccueilComponent implements OnDestroy {
  breadcrumbItems: MenuItem[];



  tieredItems: MenuItem[];

  items: MenuItem[];

  page_dmde: any;

  demandeConcept = DemandeConstants.CONCEPT_METIER_DEMANDE;
  demandeFonction = DemandeConstants.FUNCTION_DEMANDE;
  demandeNotificationIcon = "pi pi-bell";
  anonymousUser: string = "ANAC";
  refreshNotification: number = 600000; // Every 10 min

  subscription: Subscription;

  activeItem: number;
  activeTopbarItem: any;
  topbarItemClick: boolean;

  notification: ActionNotification;
  displayDemande: boolean;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public habilitation: DemandeHabilitation,
    private logService: LogService,
    private router: Router,
    public translate: TranslateService
  ) {
    this.subscription = breadcrumbService.itemsHandler.subscribe((response) => {
      this.breadcrumbItems = response;
    });

    // Register translation languages
    translate.addLangs([Language.Francais,Language.English]);
    // Set default language
    translate.setDefaultLang(Language.Francais);
  }
  mobileMegaMenuItemClick(index) {
    // this.appMain.megaMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  ngOnInit() {
    this.items = [
      {
        label: "Survol & Atterrisage",
        icon: "pi pi-fw pi-sync",
        routerLink: DemandeConstants.ROUTE_DEMANDE_LIST,
      },
      {
        label: "Licence de personnel",
        icon: "pi pi-fw pi-id-card",
        routerLink: DemandeConstants.ROUTE_DEMANDE_LIST,
      },
      {
        label: "Apropos",
        icon: "pi pi-fw pi-book",
        routerLink: HomeConstants.ROUTE_APROPOS,
      },
    ];

    this.style_scripts();

     

  }
  style_scripts() {
    const MirageLanding = {
      addClass: function (element, className) {
        if (element.classList)
          element.classList.add(className);
        else
          element.className += ' ' + className;
      },

      removeClass: function (element, className) {
        if (element.classList)
          element.classList.remove(className);
        else
          element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      },

      hasClass: function (element, className) {
        if (element.classList)
          return element.classList.contains(className);
        else
          return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
      },

      hideMenu: function () {
        this.removeClass(document.getElementById('landing-menu'), 'landing-menu-active');
        this.removeClass(document.getElementById('landing-menu-button'), 'landing-menu-active');
        this.removeClass(document.getElementById('landing-mask'), 'landing-menu-active');
      },

      showMenu: function () {
        this.addClass(document.getElementById('landing-menu'), 'landing-menu-active');
        this.addClass(document.getElementById('landing-menu-button'), 'landing-menu-active');
        this.addClass(document.getElementById('landing-mask'), 'landing-menu-active');
      }
    };

    document.getElementById('landing-menu-button').addEventListener('click', function (e) {
      var menu = document.getElementById('landing-menu');

      if (MirageLanding.hasClass(menu, 'landing-menu-active')) {
        MirageLanding.hideMenu();
      } else {
        MirageLanding.showMenu();
      }

      e.preventDefault();
    });

    // document.getElementById('landing-mask').addEventListener('click', function (e) {
    //   MirageLanding.hideMenu();

    //   e.preventDefault();
    // });

    var menuLinks = document.querySelectorAll('#landing-menu a');
    for (let i = 0; i < menuLinks.length; i++) {
      menuLinks[i].addEventListener('click', function (e) {
        MirageLanding.hideMenu();
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logout() {
    this.habilitation.logout();
  }

  pageDmde(valeur) {
    alert();
    this.page_dmde = valeur;
  }

  viewNotification(event) {
    this.logService.log("viewNotification");
    this.logService.log(event);
    this.notification = event;

    this.logService.log("viewNotification");
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_CREATE_RECAPITULATIF +
      "?id=" +
      event.tracking.idObject
    );
  }

  translateLanguageTo(lang: string) {
    this.translate.use(lang);
  }
}
