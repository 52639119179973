import { User } from 'src/app/modules/security/models/user';
import { Step } from 'src/app/modules/setting/models/workflow';

export interface Personne {
	id: string;
	reference: string;
	typePersonne: string;
	telephone: string;
	email: string;
	adresse: string;
	referentielPersonne: any;
	etat: Step;
}

export class PersonnePhysique {
	id: string;
	reference: string;
	typePersonne: string;
	telephone: string;
	email: string;
	adresse: string;
	referentielPersonne: any;
	etat: any;
	nom: string;
	prenoms: string;
	dateNaissance: Date;
	lieuNaissance: string;
	nationalite: string;
	societe: PersonneMorale;
	fullname: string;
	utilisateur: User;
	demandeur: boolean;

}


export class PersonneMorale {
	id: string;
	reference: string;
	typePersonne: string;
	telephone: string;
	email: string;
	adresse: string;
	referentielPersonne: any;
	etat: any;
	raison: string;
	domaine: string;
	codeOACI: string;
	codeIATA: string;
}
