import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeDocumentComponent } from './components/type-document/type-document.component';
import { ObjectStateComponent } from '../setting/components/workflow/object-state/object-state.component';
import { SettingModule } from '../setting/setting.module';




@NgModule({
  declarations: [
    TypeDocumentComponent,
  ],
  imports: [
    CommonModule,
    SettingModule
  ]
})
export class GedModule { }
