import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";

const URL_TYPE_DEMANDE: string = environment.gasAPIUrl + "/demande/type";

@Injectable({
  providedIn: "root",
})
export class TypeDemandeService extends CrudService {
  ressourceBaseURL(): string {
    return URL_TYPE_DEMANDE;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }
  getAll(typeService: string): Observable<any[]> {
    let url = this.ressourceBaseURL() + "/getAll";
    if (typeService) {
      url = url + "?typeService=" + typeService;
    }
    this.logService.log("TypeDemandeService url: " + url);
    return this.httpClient.get<any[]>(url).pipe(
      catchError((error: any): Observable<any[]> => {
        this.logService.error(error);
        return of(null);
      })
    );
  }
}
