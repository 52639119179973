export class DemandeConstants {
  static ROUTE_DEMANDE_LIST = "/accueil/list-demande";
  static ROUTE_DEMANDE_CREATE = "/accueil/demande";
  static ROUTE_DEMANDE_CREATE_INFOS = "/accueil/demande/infos";
  static ROUTE_DEMANDE_CREATE_PIECES = "/accueil/demande/pieces";
  static ROUTE_DEMANDE_CREATE_PAIEMENT = "/accueil/demande/paiement";
  static ROUTE_DEMANDE_CREATE_RECAPITULATIF = "/accueil/demande/recapitulatif";

  static ROUTE_DEMANDE_VIEW_INFOS = "/accueil/infos";
  static ROUTE_DEMANDE_VIEW_PIECES = "/accueil/pieces";
  static ROUTE_DEMANDE_VIEW_PAIEMENT = "/accueil/paiement";
  static ROUTE_DEMANDE_VIEW_RECAPITULATIF = "/accueil/recapitulatif";
  static ROUTE_DEMANDE_FORM_FEEDBACK = "/accueil/feedback";

  static GROUPE_EXTENSION_SURVATTER = "SURVATTER_GEXT";
  static CATEGORIE_DELIVRANCE_SURVATTER = "DEL_AUTO_SURVATTER";

  static FUNCTION_DEMANDE = "SET_FC_DEMANDE";
  static CONCEPT_METIER_DEMANDE = "DEMANDE";

  static FUNCTION_PAIEMENT = "SET_FC_PAIEMENT";
  static CONCEPT_METIER_PAIEMENT = "PAIEMENT";

  static CONCEPT_METIER_PIECEJONTE = "PIECE_JOINTE";

  static CODIFICATION_MODE_PAIEMENT = "MODE_PAIEMENT";
  static CODIFICATION_OPTION_PAIEMENT = "OPTION_PAIEMENT";
}

export enum TypePaiementConstants {
  TYPE_PAIEMENT_COMPTANT = "TYPE_PAIEMENT_COMPTANT",
  TYPE_PAIEMENT_ONLINE = "TYPE_PAIEMENT_ONLINE",
}

export enum CodificationModePaiement {
  ESPECE = "MODE_PAIEMENT_ESPECE",
  CHEQUE = "MODE_PAIEMENT_CHEQUE",
  CARTE_BANCAIRE = "MODE_PAIEMENT_CARTE",
  VIREMENT = "MODE_PAIEMENT_VIREMENT",
  MOBILE_MONEY = "MODE_PAIEMENT_MOBILE",
}

export enum CodificationOptionPaiement {
  REDEVANCE = "OPTION_PAIEMENT_REDEVANCE",
  TOTALITE = "OPTION_PAIEMENT_TOTALITE",
  RESTE = "OPTION_PAIEMENT_RESTE",
  EXONERATION = "OPTION_PAIEMENT_EXO",
}

export enum CodificationSurvatterType {
  SURVOL = "SURVATTER_TYPE_SURVOL",
  SURVOL_ATTERISSAGE = "SURVATTER_TYPE_ATTER",
}

export enum TypeServiceConstants {
  SURVATTER = "SURVATTER",
  PEL = "PEL",
}

export enum TypeDemandeConstants {
  SURVATTER = "SURVATTER",
  PEL_CARTE = "PEL_CARTE",
  PEL_LIC_PNT = "PEL_LIC_PNT",
  PEL_LIC_PNC = "PEL_LIC_PNC",
  PEL_LIC_TMA = "PEL_LIC_TMA",
  PEL_LIC_ATC = "PEL_LIC_ATC",
  PEL_LIC_ATE = "PEL_LIC_ATE",
  PEL_LIC_ULM = "PEL_LIC_ULM",
  PEL_LIC_TPL = "PEL_LIC_TPL",
}

export enum GroupeExtensionConstants {
  GROUPE_EXTENSION_SURVATTER = "SURVATTER_GEXT",
}

export enum ExtensionSurvatterConstants {
  SURVATTER_TYPE = "SURVATTER_TYPE",
  SURVATTER_APPL = "SURVATTER_APPL",
  SURVATTER_DATEESCALE = "SURVATTER_DESC",
  SURVATTER_ASS_ESCALE="SURVATTER_ASS",
  SURVATTER_PERSACONTACTER ="SURVATTER_PAC",
  SURVATTER_MEDECIN="SURVATTER_MED",

  SURVATTER_DEPDT="SURVATTER_DEPDT",
  SURVATTER_ARRDT="SURVATTER_ARRDT",
  SURVATTER_HENT = "SURVATTER_HENT",
  SURVATTER_HEXIT = "SURVATTER_HEXIT",
}


export enum CategorieDemandeConstants {
  DEL_AUTO_SURVATTER = "DEL_AUTO_SURVATTER",
}

export enum ProfilUtilisteur {
  DEMANDEUR = "PROFIL_DEMANDEUR",
}

export enum TypeDemandeMontantConstants {
  SURVATTER_0 = "SURVATTER_0",
  SURVATTER_0_3 = "SURVATTER_0.3",
  SURVATTER_3_6 = "SURVATTER_3.6",
  SURVATTER_6_12 ="SURVATTER_6.12"
}
