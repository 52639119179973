
export class AuthUser {
    id: string;
    personneId: string;
    email: string;
    username: string;
    fullname: string;
    firstName: string;
    lastName: string;
    phone: string;
    adresse: string;

    userRole: string;
    token: string;
    refreshToken: string;
    tokenLifetime: number;
    authorities: string[];
    authProvider: string;
}

export class AuthenticationInfo {
    id: number;
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
}

export class LoginInfo {
    username: string;
    password: string;
}

export class Habilitation {
    static HABILITATION_READ = 0;
    static HABILITATION_CREATE = 1;
    static HABILITATION_UPDATE = 2;
    static HABILITATION_DELETE = 3;
    static HABILITATION_VALIDATE = 4;
    static HABILITATION_ADMINISTRATE = 99;

}
