import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { NotificationService } from '../services/notification.service';
import { LogService } from '../services/log.service';
import { NgIdleDetectorService } from 'ng-idle-detector';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    horizontalMenu: boolean;

    darkMode = false;

    menuColorMode = 'light';

    menuColor = 'layout-menu-light';

    themeColor = 'blue';

    layoutColor = 'blue';

    ripple = true;

    inputStyle = 'outlined';

    constructor(private primengConfig: PrimeNGConfig,public translate: TranslateService,
        private ngIdleDetectorService: NgIdleDetectorService,
        private logService: LogService,
        private router: Router,
        private notificationService: NotificationService,
        private authService: AuthService) { }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.ngIdleDetectorService.startWatching(environment.idleSessionTimeout)
            .subscribe((isExpired: boolean) => {
                    if (isExpired && this.authService.isLoggedIn()) {
                        this.logService.log('session expired');
                        this.notificationService.warningMessage('Session inactive', 'Vous devez vous reconnecter!');
                        this.authService.delayLogout();
                    }
            });
    }

    //Switch language
  translateLanguageTo(lang: string) {
    this.translate.use(lang);
  }
}
