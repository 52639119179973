import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { FormService } from "src/app/modules/shared/services/form.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.css"],
})
export class ForgetPasswordComponent implements OnInit {
  email: string;
  actionResetPassword: boolean;
  actionSendActivation: boolean;

  constructor(
    private logService: LogService,
    private authService: AuthService,
    private formService: FormService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.forEach((params) => {
      this.logService.log(params);
      if (params.action && params.action.toLocaleUpperCase() == "ACTIVATION") {
        this.actionSendActivation = true;
      }
    });
  }

  forgetPwd(form: NgForm) {
    if (this.formService.isNgFormValid(form)) {
      this.authService.sendForgetPwdLink(this.email).subscribe((data) => {
        if (data) {
          this.notificationService.successMessage(
            "Le lien de réinitialisation de mot de passe vous est envoyé par mail"
          );
          // setTimeout(() => {
          //   this.router.navigateByUrl("/login");
          // }, 5000);
          this.authService.delayLogout();
        } else {
          // something went wrong
          this.notificationService.serverError();
        }
      });
    }
  }
  sendActivationEmail(form: NgForm) {
    if (this.formService.isNgFormValid(form)) {
      this.authService.sendActivationMail(this.email).subscribe((data) => {
        if (data) {
          this.notificationService.successMessage(
            "Le lien d'activation de compte vous est envoyé par mail"
          );
          // setTimeout(() => {
          //   this.router.navigateByUrl('/login');
          // }, 5000);
          this.authService.delayLogout();
        } else {
          // something went wrong
          this.notificationService.serverError();
        }
      });
    }
  }
}
