import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/modules/shared/services/crud.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { environment } from 'src/environments/environment';

const URL_PERSONNE_MORALE: string = environment.gasAPIUrl + '/personne/pm';

@Injectable({
  providedIn: 'root'
})
export class PersonneMoraleService extends CrudService {

  ressourceBaseURL(): string {
    return URL_PERSONNE_MORALE;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }
}
