import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";

import jsPDF from "jspdf";
import * as QRCode from "qrcode";
import { Attestation } from "../models/attestation/attestation";
import { Demande, Feeback } from "../models/demande";
import { AttestationFooter } from "../models/attestation/attestation-footer";
import { AttestationContent } from "../models/attestation/attestation-content";
import { AttestationBody } from "../models/attestation/attestation-body";
import { ValeurExtension } from "src/app/modules/setting/models/extension";
import dateFormat, { masks } from "dateformat";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import autoTable from "jspdf-autotable";

const URL_DEMANDE: string = environment.gasAPIUrl + "/demande";

@Injectable({
  providedIn: "root",
})
export class DemandeService extends CrudService {
  ressourceBaseURL(): string {
    return URL_DEMANDE;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }

  downloadDocumentDelivre(idDmde: string, typeService:string, download?:boolean): Observable<any> {
    let url =this.ressourceBaseURL() + "/delivrance/download/" + idDmde;
    url =url+"?typeService="+typeService;
    if(download){
      url =url+"&download="+download;
    }
    return this.httpClient
      .get<any>(url, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  isDemandePaid(idDmde: string): Observable<boolean> {
    return this.httpClient
      .get<Feeback>(this.ressourceBaseURL() + "/isDemandePaid/" + idDmde)
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  saveFeedback(idDmde: string, feedback: Feeback): Observable<Feeback> {
    return this.httpClient
      .post<Feeback>(
        this.ressourceBaseURL() + "/feedback/" + idDmde + "/save/",
        feedback
      )
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  feedback(idDmde: string): Observable<Feeback> {
    return this.httpClient
      .get<Feeback>(this.ressourceBaseURL() + "/feedback/" + idDmde)
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  feedbacks(): Observable<Demande[]> {
    return this.httpClient
      .get<Demande[]>(this.ressourceBaseURL() + "/feedbacks")
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  printAttestationSurvatter(
    demande: Demande,
    valeurs: ValeurExtension[]
  ): any {
    if (!demande.documentLivre) {
      return;
    }
    const attestation = new Attestation();
    attestation.body = new AttestationBody();
    attestation.body.numeroDemande = demande.documentLivre.code;
    attestation.body.validite =
      "valide" +
      (demande.typeDemandeMontant.delaiValidite == 0
        ? " 72 heures"
        : " " + demande.typeDemandeMontant.delaiValidite + " Mois");
    attestation.body.content = new AttestationContent();

    let proprietaire = valeurs.find((v) => v.extension.id == "SURVATTER_OWNER");
    let exploitant = valeurs.find((v) => v.extension.id == "SURVATTER_EXPL");
    let typeAeronef = valeurs.find((v) => v.extension.id == "SURVATTER_NB");
    let immatriculation = valeurs.find(
      (v) => v.extension.id == "SURVATTER_IMMA"
    );
    let nationalite = valeurs.find((v) => v.extension.id == "SURVATTER_NAT");
    let indicatif = valeurs.find((v) => v.extension.id == "SURVATTER_INDIC");
    let itineraire = valeurs.find((v) => v.extension.id == "SURVATTER_ITI");
    let dateHeureEntree = valeurs.find(
      (v) => v.extension.id == "SURVATTER_HENT"
    );

    let objet = valeurs.find((v) => v.extension.id == "SURVATTER_FLOBJ");

    attestation.body.content.contentInfo = [
      {
        fr: "DEMANDEUR",
        en: "APPLICANT",
        value: demande.personne.nom + " " + demande.personne.prenoms,
      },
      { fr: "PROPRIETAIRE", en: "OWNER", value: proprietaire?.valeur },
      { fr: "EXPLOITANT", en: "OPERATOR", value: exploitant?.valeur },
      { fr: "TYPE AERONEF", en: "AIRCRAFT TYPE", value: typeAeronef?.valeur },
      {
        fr: "IMMATRICULATION",
        en: "REGISTRATION",
        value: immatriculation?.valeur,
      },
      { fr: "NATIONALITE", en: "NATIONALITY", value: nationalite?.valeur },
      { fr: "INDICATIF", en: "CALL SIGN", value: indicatif?.valeur },
      {
        fr: "ITINERAIRE/ DATE/ HORAIRE",
        en: "(ROUTE / DATE/ TIME)",
        value:
          itineraire?.valeur +
          " " +
          dateFormat(new Date(dateHeureEntree.valeurDate), "dd/mm/yyyy, HH:MM"),
      },
    ];
    attestation.body.content.motif = {
      fr: "MOTIF",
      en: "PURPOSE",
      value: objet?.valeur,
    };

    attestation.footer = new AttestationFooter();
    attestation.footer.titrePoste = {
      fr: "LE DIRECTEUR GENERAL",
      en: "The Director General",
      nomDetenteur: demande.documentLivre.signataire,
      qrCode: demande.documentLivre.id + "/" + demande.documentLivre.uid,
    };

    const margin = {
      x: 13,
      y: 15,
    };
    const doc = new jsPDF();
    const totalPagesExp = "{total_pages_count_string}";
    const scale = 8;

    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    // ENTETE DE L'ATTESTATION
    const img = new Image();
    img.src = `${environment.logoBaseUrl}/ANAC.jpg`;

    if (img) {
      doc.addImage(
        img,
        "PNG",
        width / 2 - 40 / 2,
        margin.y + 5,
        40,
        30,
        undefined,
        "FAST"
      );
      doc.saveGraphicsState();
      doc.setGState(doc.GState({ opacity: 0.2 }));
      doc.addImage(
        img,
        "PNG",
        width / 2 - 100 / 2,
        height / 2 - 100 / 2,
        100,
        100,
        undefined,
        "FAST"
      );
      doc.setGState(doc.GState({ opacity: 1 }));
    }
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(10);
    const MINISTERE =
      "MINISTERE DES TRANSPORTS ROUTIERS, AERIENS ET FERROVIAIRES";
    doc.text(MINISTERE, 75 / 2 + margin.x, margin.y, {
      maxWidth: 75,
      align: "center",
    });
    doc.setFontSize(8);
    doc.setTextColor(40);
    doc.text(
      "AGENCE NATIONALE DE L’AVIATION CIVILE",
      75 / 2 + margin.x,
      margin.y + 12,
      { maxWidth: 100, align: "center" }
    );

    doc.text(
      "ANAC/DG/DTA/SRETA",
      75 / 2 + margin.x,
      margin.y + 16,
      { maxWidth: 100, align: "center" }
    );
    
    doc.text("REPUBLIQUE TOGOLAISE", width - (60 / 2 + margin.x), margin.y, {
      maxWidth: 60,
      align: "center",
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      "Travail - Liberté - Patrie",
      width - (60 / 2 + margin.x),
      margin.y + 5,
      { maxWidth: 100, align: "center" }
    );

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(14);

    // TITRE DE L'ATTESTATION
    const ATTESTATION_TITLE_START = 50;
    const FR_TITLE = "Fiche d’autorisation de survol / Atterrissage";
    const EN_TITLE = "Overflight/Landing authorization form";

    doc.text(FR_TITLE, width / 2, margin.y + ATTESTATION_TITLE_START, {
      maxWidth: width,
      align: "center",
    });
    doc.line(
      width / 4 + 1,
      margin.y + ATTESTATION_TITLE_START + 1,
      width - 54,
      margin.y + ATTESTATION_TITLE_START + 1
    );

    doc.setFont("Helvetica", "bolditalic");
    const EN_TITLE_STARTY = margin.y + ATTESTATION_TITLE_START + 8;
    doc.text(EN_TITLE, width / 2, EN_TITLE_STARTY, {
      maxWidth: width,
      align: "center",
    });
    doc.line(
      width / 4 + 9,
      EN_TITLE_STARTY + 1,
      width - 61,
      EN_TITLE_STARTY + 1
    );

    // TITRE DU CONTENU
   
    const TITRE_CONTENU = `AUTORISATION DE SURVOL/ATTERRISSAGE N° ${attestation.body?.numeroDemande}`;
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    const TITRE_CONTENU_STARTY = EN_TITLE_STARTY + 15;
    doc.text(TITRE_CONTENU, width / 2, TITRE_CONTENU_STARTY, {
      maxWidth: width,
      align: "center",
    });
    
    // Numero du dossier 
    const TEXTE_DOSSIER = `DOSSIER N° ${demande.reference}`;
    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");

    doc.text(TEXTE_DOSSIER, width / 2, TITRE_CONTENU_STARTY + 8, {
      maxWidth: 100,
      align: "center",
    });

    // TEXTE DE LA VALIDITE
    const TEXTE_VALIDITE = `(${attestation.body?.validite})`;
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");

    doc.text(TEXTE_VALIDITE, width / 2, TITRE_CONTENU_STARTY + 16, {
      maxWidth: 100,
      align: "center",
    });

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);

    // TEXTE DE LA VALIDITE
    const CONTENU = attestation.body?.content?.contentInfo?.map((e) => {
      return { nom: `${e.fr} / ${e.en} :`, valeur: e.value };
    });

    let CONTENUITEMN_INDEX = 25;

    const FOOTER_LINE_START_X = width / 8;
    const FOOTER_LINE_START_Y = TITRE_CONTENU_STARTY + CONTENUITEMN_INDEX + 80;
    const motif = attestation.body?.content?.motif;
    autoTable(doc, ({
      startY: TITRE_CONTENU_STARTY + CONTENUITEMN_INDEX,
      
      styles: { overflow: 'linebreak'},
      columnStyles: { valeur: {cellWidth: 130 }, libelle: {cellWidth: 55, fontStyle: 'bold' } }, // European countries centered
      headStyles: {},
      body: CONTENU.map(v => {return {"libelle":v.nom, "valeur":v.valeur}}).concat({"libelle":`${motif?.fr} / ${motif?.en}`, "valeur":motif.value}),
      didParseCell(data) {
        data.cell.styles.fillColor = null;
      },
      columns: [
        { header: null, dataKey: 'libelle' },
        { header: null, dataKey: 'valeur' },
      ],
      didDrawPage(data){
        const signatureImg = new Image();
        if (signatureImg.src) {
          doc.addImage(
            signatureImg,
            "PNG",
            FOOTER_LINE_START_X - 13,
            data.cursor.y + 20,
            40,
            40,
            undefined,
            "FAST"
          );
        }

        // POSTE ET NOM
        doc.setFont("Helvetica", "bold");
        doc.text(
          `${attestation.footer?.titrePoste?.fr}`,
          width - width / 4,
          data.cursor.y + 25,
          { maxWidth: 50, align: "center" }
        );
        doc.text(
          `(${attestation.footer?.titrePoste?.en})`,
          width - width / 4,
          data.cursor.y + 30,
          { maxWidth: 50, align: "center" }
        );
      }
      }));

    // MOTIF
    doc.setLineWidth(1.0);
    doc.line(
      FOOTER_LINE_START_X - 10,
      FOOTER_LINE_START_Y + 10,
      width - FOOTER_LINE_START_X + 10,
      FOOTER_LINE_START_Y + 10
    );

    // PIED DE PAGE
    const INFOLINE_HEIGHT = 10;
    doc.setFillColor(47, 85, 151);
    const rectY = height - 10 - margin.y;
    doc.rect(margin.x, rectY, width - margin.x * 2, INFOLINE_HEIGHT, "F");
    doc.setFontSize(9);
    doc.setTextColor("white");
    doc.setFont("Helvetica", "bold");
    doc.text(
      "BP : 2699 Lomé – Tél : 22 26 37 40/22 26 85 97 – Fax : 22 26 08 60 – E-mail :survatter@yahoo.fr – survatter@anac-togo.tg",
      width / 2,
      rectY + INFOLINE_HEIGHT / 2 + 1,
      { maxWidth: width, align: "center" }
    );

    const date = new Date();
    try {
      doc.save(`ATTESTATION-${date.toUTCString()}.pdf`);
    } catch (error) {
      this.logService.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }
}
