import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { FormService } from 'src/app/modules/shared/services/form.service';
import { HabilitationService } from 'src/app/modules/shared/services/habilitation.service';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';
import {
  PersonneMorale,
  PersonnePhysique,
} from '../../../demande/models/personne';
import { PersonneMoraleService } from '../../../demande/services/personne/personne-morale.service';
import { PersonnePhysiqueService } from '../../../demande/services/personne/personne-physique.service';
import { CompteConstants } from '../../models/compte-constants';

@Component({
  selector: 'app-mon-compte',
  templateUrl: './mon-compte.component.html',
  styleUrls: ['./mon-compte.component.css'],
})
export class MonCompteComponent implements OnInit {
  personne: PersonnePhysique;
  societe: PersonneMorale;
  editableCompte: boolean;
  editableSociete: boolean;

  constructor(
    private authService: AuthService,
    public habilitation: HabilitationService,
    private personnePhysiqueService: PersonnePhysiqueService,
    private personneMoraleService: PersonneMoraleService,
    private notificationService: NotificationService,
    private formService: FormService
  ) {}

  ngOnInit(): void {
    if (this.authService.getUserPersonneId()) {
      this.init();
    }
  }

  saveCompte(form: NgForm) {
    if (this.editableCompte && this.formService.isNgFormValid(form)) {
      this.personnePhysiqueService
        .updateProfile(this.personne)
        .subscribe((data) => {
          if (data) {
            this.personne = data;
            this.authService.setUserFullName(this.personne.nom + ' ' + this.personne.prenoms);
            if (!this.personne.societe) {
              this.personne.societe = {} as PersonneMorale;
            }
            this.editableCompte = false;
            this.notificationService.successMessage();
          } else {
            this.notificationService.errorMessage();
          }
        });
    }
  }

  init() {
    this.editableCompte = false;
    this.editableSociete = false;
    this.personnePhysiqueService
      .find(this.authService.getUserPersonneId())
      .subscribe((data) => {
        if (data) {
          this.personne = data;
          if (!this.personne.societe) {
            this.personne.societe = {} as PersonneMorale;
            this.personne.societe.referentielPersonne = {
              id: CompteConstants.REFERENTIEL_DEMANDEUR_ID,
            };
          }
        }
      });
  }

  saveSociete(form: NgForm) {
    if (this.editableSociete && this.formService.isNgFormValid(form)) {
      this.personnePhysiqueService
        .updateSociete(
          this.authService.getUserPersonneId(),
          this.personne.societe
        )
        .subscribe((data) => {
          if (data) {
            this.personne = data;
            if (!this.personne.societe) {
              this.personne.societe = {} as PersonneMorale;
            }
            this.editableSociete = false;
            this.notificationService.successMessage();
          } else {
            this.notificationService.errorMessage();
          }
        });
    }
  }
}
