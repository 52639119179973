import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'montant'
})
export class MontantPipe implements PipeTransform {

  transform(value: string, args: string): string {
    const separator = '.';

    if (value == undefined) {
      return '';
    }
    const n = parseInt(value);

    const rx = /(\d+)(\d{3})/;
    return String(n).replace(/^\d+/, function(w) {
      let res = w;
      while (rx.test(res)) {
        res = res.replace(rx, '$1' + separator + '$2');
      }
      return res;
    });
  }
}
