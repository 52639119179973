import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { User } from "src/app/modules/security/models/user";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { Habilitation } from "src/app/modules/shared/models/habilitation";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { FormService } from "src/app/modules/shared/services/form.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { PersonnePhysique } from "../../../demande/models/personne";
import { CompteConstants } from "../../models/compte-constants";

@Component({
  selector: "app-creer-compte",
  templateUrl: "./creer-compte.component.html",
  styleUrls: ["./creer-compte.component.css"],
})
export class CreerCompteComponent implements OnInit {
  dark: boolean;

  checked: boolean;
  validCaptcha: boolean;

  personne: PersonnePhysique = new PersonnePhysique();
  error: any;
  intervalId: any;

  constructor(
    private logService: LogService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private formService: FormService,
    private router: Router,
    private notificationService: NotificationService,
    private messageService: MessageService
  ) {
    this.breadcrumbService.setItems([
      { label: "Personne" },
      { label: "compte" },
      { label: "Création" },
    ]);
  }

  showResponse(event) {
    if (event && event.response) {
      // check Captcha
      this.validCaptcha = true;
      // this.authService.checkCaptha(event.response).subscribe((data) => {
      //   this.logService.log("check Captcha: " + data);
      //   this.validCaptcha = true;
      // });
    }
  }

  ngOnInit() {
    this.personne.utilisateur = new User();
  }

  inscription(regform: NgForm) {
    if (!this.validCaptcha) {
      this.notificationService.errorMessage(
        "Veuillez confirmer que vous n'êtes pas un robot!",
        ""
      );
      return;
    }

    if(isNaN(Number(this.personne.telephone))){
      this.notificationService.errorMessage(
        "Téléphone est incorrect",
        "Corriger le numéro de téléphone, seuls les caractères numériques sont autorisés!"
      );
      return;
    }

    if (this.formService.isNgFormValid(regform)) {
      if (
        this.personne.utilisateur.password !=
        this.personne.utilisateur.confirmPassword
      ) {
        this.notificationService.errorMessage(
          "Mot de passe est incorrect",
          "Le mot de passe saisi et sa confirmation ne concordent pas!"
        );
        return;
      }
      this.personne.nom = this.personne.utilisateur.firstName;
      this.personne.prenoms = this.personne.utilisateur.lastName;
      this.personne.email = this.personne.utilisateur.email;

      this.personne.demandeur = true;

      this.personne.referentielPersonne = {
        id: CompteConstants.REFERENTIEL_DEMANDEUR_ID,
      };

      this.personne.utilisateur.username = this.personne.utilisateur.email;
      this.personne.utilisateur.profils = [];
      this.personne.utilisateur.profils.push({
        id: CompteConstants.PROFIL_USER_DEMANDEUR_ID,
      });

      this.authService.register(this.personne).subscribe((personne) => {
        if (personne.id) {
          this.notificationService.successMessage(
            "Veuillez vérifier votre boite electronique pour activer votre compte utilisateur!"
          );

          this.notificationService.infoMessage(
            "L'ANAC procèdera à une verification!"
          );

          // setTimeout(() => {
          //   this.router.navigateByUrl(CompteConstants.ROUTE_LOGIN);
          // }, 1000);
          this.authService.delayLogout(1000);
        } else {
          // something went wrong
          this.notificationService.serverError();
        }
      });
    }
  }
}
