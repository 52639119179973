import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { User } from "src/app/modules/security/models/user";
import { CrudService } from "src/app/modules/shared/services/crud.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { environment } from "src/environments/environment";
import { Action } from "../../models/workflow";

const URL_WORKFLOW_ACTION: string =
  environment.apiUrl + "/workflow/step/action";

@Injectable({
  providedIn: "root",
})
export class ActionService extends CrudService {
  ressourceBaseURL(): string {
    return URL_WORKFLOW_ACTION;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }

  findStepActions(idStep: string): Observable<Action[]> {
    return this.httpClient
      .post<any>(this.ressourceBaseURL() + "/list/" + idStep, {})
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  findUsersByAction(
    idAction: string,
    fonction: string,
    restrictedProfiles: string[]
  ): Observable<User[]> {
    let profiles: string = "";
    if (restrictedProfiles && restrictedProfiles.length > 0) {
      restrictedProfiles.forEach((p) => {
        profiles = profiles + (profiles.length > 0 ? "," : "") + p;
      });
    }
    return this.httpClient
      .post<any>(
        this.ressourceBaseURL() +
          "/" +
          idAction +
          "/users?fonction=" +
          fonction,
        { profiles }
      )
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }

  findNextUsersByAction(
    idObject: string,
    concept: string,
    idAction: string,
    fonction: string,
    restrictedProfiles: string[]
  ): Observable<any> {
    let profiles: string = "";
    if (restrictedProfiles && restrictedProfiles.length > 0) {
      restrictedProfiles.forEach((p) => {
        profiles = profiles + (profiles.length > 0 ? "," : "") + p;
      });
    }
    return this.httpClient
      .post<any>(
        this.ressourceBaseURL() +
          "/next/" +
          idAction +
          "/users/" +
          idObject +
          "?concept=" +
          concept +
          "&fonction=" +
          fonction,
        { profiles }
      )
      .pipe(
        catchError((error: any): Observable<any> => {
          this.logService.error(error);
          return of(null);
        })
      );
  }
}
