import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListDmdeComponent } from './components/list-dmde/list-dmde.component';
import { RouterModule } from '@angular/router';
import { StepsModule } from 'primeng/steps';
import { FormulaireComponent } from './components/formulaire/formulaire.component';
import { PiecesJointesComponent } from './components/pieces-jointes/pieces-jointes.component';
import { PaiementComponent } from './components/paiement/paiement.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { RecapitulatifComponent } from './components/recapitulatif/recapitulatif.component';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { NgHttpLoaderModule } from 'ng-http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemandeHabilitation } from './models/habilitation/demande-habilitation';
import { SettingModule } from '../../setting/setting.module';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TagModule } from 'primeng/tag';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PaiementStatusComponent } from './components/paiement/paiement-status/paiement-status.component';
import { PieceStatusComponent } from './components/pieces-jointes/piece-status/piece-status.component';
import { DemandeSoumissionService } from './services/demande-soumission.service';
import { CreationStepComponent } from './components/creation-step/creation-step.component';
import { SurvatterComponent } from './components/survatter/survatter.component';
import { PelComponent } from './components/pel/pel.component';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { PaiementTransactionComponent } from './components/paiement/paiement-transaction/paiement-transaction.component';
import { MontantUtils } from './models/montant-utils';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FeedbackNotificationComponent } from './components/feedback/feedback-notification/feedback-notification.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BadgeModule } from 'primeng/badge';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    ListDmdeComponent,
    CreationStepComponent,
    FormulaireComponent,
    PiecesJointesComponent,
    PaiementComponent,
    RecapitulatifComponent,
    PaiementStatusComponent,
    PieceStatusComponent,
    SurvatterComponent,
    PelComponent,
    PaiementTransactionComponent,
    FeedbackComponent,
    FeedbackNotificationComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),
    TranslateModule.forRoot(
      {
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }
  ),
    SettingModule,

    StepsModule,
    FileUploadModule,
    ToastModule,
    HttpClientModule,
    DropdownModule,
    CalendarModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    CheckboxModule,
    CardModule,
    CalendarModule,
    InputNumberModule,
    TableModule,
    TimelineModule,
    DialogModule,
    ConfirmDialogModule,
    FileUploadModule,
    TagModule,
    SelectButtonModule,
    RadioButtonModule,
    DividerModule,
    TooltipModule,
    FieldsetModule,
    TabViewModule,
    OverlayPanelModule,
    BadgeModule,

    PdfViewerModule,

  ],
  exports: [
    FeedbackNotificationComponent,
  ],
  providers: [
    DemandeHabilitation, DemandeSoumissionService, MontantUtils
  ]
})
export class DemandeModule { }
