import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccueilComponent } from './components/accueil/accueil.component';
import { LoginComponent } from './components/login/login.component';
import { SplitterModule } from 'primeng/splitter';
import { RouterModule } from '@angular/router';
import { AccueilContentComponent } from './components/accueil-content/accueil-content.component';
import { MenubarModule } from 'primeng/menubar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AproposComponent } from './components/apropos/apropos.component';
import { HomeHabilitation } from './models/home-habilitation';
import { SettingModule } from '../../setting/setting.module';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CaptchaModule } from 'primeng/captcha';
import { DemandeModule } from '../demande/demande.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [
    AccueilComponent,
    LoginComponent,
    AccueilContentComponent,
    AproposComponent,


  ],
  imports: [
    CommonModule,
    SplitterModule,
    RouterModule,
    MenubarModule,
    TieredMenuModule,
    FormsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),

    SettingModule,
    DemandeModule,
    TranslateModule.forRoot(
      {
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }
  ),

    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    PasswordModule,
    ToastModule,
    CheckboxModule,
    BreadcrumbModule,
    BadgeModule,
    AvatarModule,
    MenubarModule,
    CaptchaModule
  ],
  providers: [
    HomeHabilitation,
  ]
})
export class HomeModule { }
