import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { DemandeConstants } from "../../models/demande-constants";
import { DemandeSoumissionService } from "../../services/demande-soumission.service";
import { TypeDemandeService } from "../../services/type-demande.service";

@Component({
  selector: "app-creation-step",
  templateUrl: "./creation-step.component.html",
  styleUrls: ["./creation-step.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CreationStepComponent implements OnInit {
  items: any[];

  @Input() typeService: string;
  subscription: Subscription;

  constructor(
    private typeDemandeService: TypeDemandeService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    let breadcrumb = [];

    this.route.queryParams.subscribe((params) => {
      if (params.ts) {
        this.typeService = params.ts;
      }

      if (this.typeService) {
        this.typeDemandeService.find(this.typeService).subscribe((t) => {
          breadcrumb.push({
            label: "Demandes",
          });
          breadcrumb.push({ label: t.libelle });
          
          breadcrumb.push({
            label: "Création",
          });
        });
      }

      this.breadcrumbService.setItems(breadcrumb);
    });
  }

  ngOnInit(): void {
    this.items = [
      {
        label: "Formulaire",
        routerLink:
          DemandeConstants.ROUTE_DEMANDE_CREATE_INFOS +
          "?ts=" +
          this.typeService,
      },
      {
        label: "Pièces jointes",
        routerLink: DemandeConstants.ROUTE_DEMANDE_CREATE_PIECES,
      },
      {
        label: "Paiement",
        routerLink: DemandeConstants.ROUTE_DEMANDE_CREATE_PAIEMENT,
      },
      {
        label: "Récapitulatif",
        routerLink: DemandeConstants.ROUTE_DEMANDE_CREATE_RECAPITULATIF,
      },
    ];
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
