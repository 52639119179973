import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { Demande } from "../../../models/demande";
import { DemandeConstants } from "../../../models/demande-constants";
import { DemandeService } from "../../../services/demande.service";
import { CacheService } from "src/app/modules/shared/services/cache.service";

@Component({
  selector: "app-feedback-notification",
  templateUrl: "./feedback-notification.component.html",
  styleUrls: ["./feedback-notification.component.css"],
})
export class FeedbackNotificationComponent implements OnInit {
  demandes: Demande[];
  selectedDemande: Demande;
  cacheKey: string = "notificationFeedback";

  constructor(
    private demandeService: DemandeService,
    private authService: AuthService,
    private router: Router,
    private logService: LogService,
    private cacheService: CacheService
  ) { }

  ngOnInit(): void {
    this.getNotification();
  }

  getNotification() {
    if (this.cacheService.has(this.cacheKey)) {
      this.demandes = this.cacheService.get(this.cacheKey) as Demande[];
      this.logService.log("end get cache " + this.cacheKey);
      return;
    }
    if (this.authService.isLoggedIn()) {
      this.demandeService.feedbacks().subscribe((data) => {
        this.logService.log("set cache " + this.cacheKey);
        this.cacheService.set(this.cacheKey, data);
        this.demandes = data;
      });
    }
  }

  onRowSelect(event) {
    if (this.selectedDemande) {
      this.router.navigateByUrl(
        DemandeConstants.ROUTE_DEMANDE_FORM_FEEDBACK +
        "?id=" +
        this.selectedDemande.id
      );
    }
  }
}
