import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Helpers } from '../models/helpers';
import { Page } from '../models/page';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export abstract class CrudService {

  constructor(protected httpClient: HttpClient, protected logService: LogService) { }

  abstract ressourceBaseURL(): string;

  find(id: string): Observable<any> {
    return this.httpClient.get<any>(this.ressourceBaseURL() + '/find/' + id
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

  exists(id: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.ressourceBaseURL() + '/exists/' + id
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }
  count(): Observable<any> {
    return this.httpClient.get<any>(this.ressourceBaseURL() + '/count'
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

   save(obj: any): Observable<any> {
    return this.httpClient.post<any>(this.ressourceBaseURL() + '/save', obj
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

  update(id: string, obj: any): Observable<any> {
    return this.httpClient.post<any>(this.ressourceBaseURL() + '/update/' + id, obj
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

  delete(id: string): Observable<Boolean> {
    return this.httpClient.post<any>(this.ressourceBaseURL() + '/delete/' + id, {}
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(false);
    }));
  }

  findAll(page: number, size: number, params: any = {}): Observable<Page<any>> {
    const cleanedParams = Helpers.cleanHttpParams(params);
    return this.httpClient.get<Page<any>>(this.ressourceBaseURL() + '?page=' + page + '&size=' + size,
      { params: cleanedParams })
      .pipe(
        catchError(
          (error: any): Observable<Page<any>> => {
            this.logService.error(error);
            return of(null);
          }
        )
      );
  }

  search(page: number, size: number, params: any = {}): Observable<Page<any>> {
    const cleanedParams = Helpers.cleanHttpParams(params);
    return this.httpClient.get<Page<any>>(this.ressourceBaseURL() + '/search?page=' + page + '&size=' + size,
      { params: cleanedParams })
      .pipe(
        catchError(
          (error: any): Observable<Page<any>> => {
            this.logService.error(error);
            return of(null);
          }
        )
      );
  }

}
