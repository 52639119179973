import { Component, OnInit } from "@angular/core";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";

@Component({
  selector: "app-apropos",
  templateUrl: "./apropos.component.html",
  styleUrls: ["./apropos.component.css"],
})
export class AproposComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService){
    this.breadcrumbService.setItems(null);
   }

  ngOnInit(): void {}
}
