import { NoPreloading, PreloadAllModules, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

import { AppErrorComponent } from "../../../pages/app.error.component";
import { CodificationComponent } from "../../setting/components/codification/codification.component";
import { ExtensionComponent } from "../../setting/components/extension/extension.component";
import { WorkflowComponent } from "../../setting/components/workflow/workflow.component";
import { PaysComponent } from "../../setting/components/pays/pays.component";
import { UserComponent } from "../../security/components/user/user.component";
import { ProfilComponent } from "../../security/components/profil/profil.component";
import { TypeDocumentComponent } from "../../ged/components/type-document/type-document.component";
import { AccueilComponent } from "../../gas/home/components/accueil/accueil.component";
import { LoginComponent } from "../../gas/home/components/login/login.component";
import { CreerCompteComponent } from "../../gas/compte/components/creer-compte/creer-compte.component";
import { AccueilContentComponent } from "../../gas/home/components/accueil-content/accueil-content.component";
import { AproposComponent } from "../../gas/home/components/apropos/apropos.component";
import { ActivationComponent } from "../../gas/compte/components/activation/activation.component";
import { ForgetPasswordComponent } from "../../gas/compte/components/forget-password/forget-password.component";
import { ResetPasswordComponent } from "../../gas/compte/components/reset-password/reset-password.component";
import { AuthGuard } from "../services/auth.guard";
import { RecoveryComponent } from "../../gas/compte/components/forget-password/recovery/recovery.component";
import { PiecesJointesComponent } from "../../gas/demande/components/pieces-jointes/pieces-jointes.component";
import { PaiementComponent } from "../../gas/demande/components/paiement/paiement.component";
import { RecapitulatifComponent } from "../../gas/demande/components/recapitulatif/recapitulatif.component";
import { ListDmdeComponent } from "../../gas/demande/components/list-dmde/list-dmde.component";
import { CreationStepComponent } from "../../gas/demande/components/creation-step/creation-step.component";
import { FormulaireComponent } from "../../gas/demande/components/formulaire/formulaire.component";
import { SurvatterComponent } from "../../gas/demande/components/survatter/survatter.component";
import { PelComponent } from "../../gas/demande/components/pel/pel.component";
import { AppAccessdeniedComponent } from "./app.accessdenied.component";
import { AppNotfoundComponent } from "./app.notfound.component";
import { MonCompteComponent } from "../../gas/compte/components/mon-compte/mon-compte.component";
import { FeedbackComponent } from "../../gas/demande/components/feedback/feedback.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          // path: '', component: AppMainComponent,
          path: "",
          component: AccueilComponent,

          children: [
            { path: "", component: AccueilContentComponent },

            // { path: '', component: DashboardDemoComponent },

            { path: "setting/codification", component: CodificationComponent },
            { path: "setting/extension", component: ExtensionComponent },
            { path: "setting/workflow", component: WorkflowComponent },
            { path: "setting/pays", component: PaysComponent },

            { path: "security/user", component: UserComponent },
            { path: "security/profil", component: ProfilComponent },

            { path: "ged/type-document", component: TypeDocumentComponent },
          ],
        },
        { path: "error", component: AppErrorComponent },
        { path: "accessdenied", component: AppAccessdeniedComponent },
        { path: "notfound", component: AppNotfoundComponent },

        { path: "login", component: LoginComponent },
        { path: "activation/:key", component: ActivationComponent },
        { path: "forget", component: ForgetPasswordComponent },
        { path: "recovery/:key", component: RecoveryComponent },
        { path: "reset-password", component: ResetPasswordComponent },
        { path: "creer-compte", component: CreerCompteComponent },

        {
          path: "accueil",
          component: AccueilComponent,
          children: [
            { path: "", component: AccueilContentComponent },
            { path: "apropos", component: AproposComponent },
            {
              path: "mon-compte",
              component: MonCompteComponent,
              canActivate: [AuthGuard],
            },
            {
              path: "infos",
              component: FormulaireComponent,
              canActivate: [AuthGuard],
            },
            {
              path: "pieces",
              component: PiecesJointesComponent,
              canActivate: [AuthGuard],
            },
            {
              path: "paiement",
              component: PaiementComponent,
              canActivate: [AuthGuard],
            },
            {
              path: "recapitulatif",
              component: RecapitulatifComponent,
              canActivate: [AuthGuard],
            },

            {
              path: "demande",
              component: CreationStepComponent,
              canActivate: [AuthGuard],
              children: [
                {
                  path: "infos",
                  component: FormulaireComponent,
                  canActivate: [AuthGuard],
                },
                {
                  path: "pieces",
                  component: PiecesJointesComponent,
                  canActivate: [AuthGuard],
                },
                {
                  path: "paiement",
                  component: PaiementComponent,
                  canActivate: [AuthGuard],
                },
                {
                  path: "recapitulatif",
                  component: RecapitulatifComponent,
                  canActivate: [AuthGuard],
                },
              ],
            },
            {
              path: "list-dmde-survatter",
              component: SurvatterComponent,
              canActivate: [AuthGuard],
            },

            {
              path: "list-dmde-pel",
              component: PelComponent,
              canActivate: [AuthGuard],
            },

            {
              path: "feedback",
              component: FeedbackComponent,
              canActivate: [AuthGuard],
            },
          ],
        },
        // { path: 'dmde-survatter', component: DmdeSurvatterComponent },
        {
          path: "informations-principales",
          component: FormulaireComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "pieces-jointes",
          component: PiecesJointesComponent,
          canActivate: [AuthGuard],
        },
        {
          path: "paiement",
          component: PiecesJointesComponent,
          canActivate: [AuthGuard],
        },
        // { path: 'list-dmde-survatter', component: ListDmdeComponent },
        { path: "**", redirectTo: "/notfound" },
      ],
      {
        scrollPositionRestoration: "enabled",
        preloadingStrategy: PreloadAllModules,
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
