import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CrudService } from 'src/app/modules/shared/services/crud.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { environment } from 'src/environments/environment';
import { ListItem } from '../../models/codification';

const URL_LIST: string = environment.apiUrl + '/setting/list';


@Injectable({
  providedIn: 'root'
})
export class ListItemService extends CrudService {

  ressourceBaseURL(): string {
    return URL_LIST;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }

  listByCodification(idCodif: string): Observable<ListItem[]> {
    const url = URL_LIST + '/' + idCodif + '/codification';
    this.logService.log('listByCodification URL: ' + url);
    return this.httpClient.get<any>(URL_LIST + '/' + idCodif + '/codification'
    ).pipe(catchError((error: any): Observable<any> => {
      this.logService.error(error);
      return of(null);
    }));
  }

}
