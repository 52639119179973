import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CodificationComponent } from "./components/codification/codification.component";
import { ExtensionComponent } from "./components/extension/extension.component";
import { PaysComponent } from "./components/pays/pays.component";
import { WorkflowComponent } from "./components/workflow/workflow.component";
import { TabViewModule } from "primeng/tabview";
import { TableModule } from "primeng/table";
import { ButtonModule } from "primeng/button";
import { InputNumberModule } from "primeng/inputnumber";
import { InputMaskModule } from "primeng/inputmask";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TagModule } from "primeng/tag";
import { ChipModule } from "primeng/chip";
import { ObjectStateComponent } from "./components/workflow/object-state/object-state.component";
import { SplitButtonModule } from "primeng/splitbutton";

import { SpeedDialModule } from "primeng/speeddial";
import { TimelineModule } from "primeng/timeline";
import { CardModule } from "primeng/card";
import { DialogModule } from "primeng/dialog";
import { SplitterModule } from "primeng/splitter";
import { MultiSelectModule } from "primeng/multiselect";
import { RouterModule } from "@angular/router";
import { MenubarModule } from "primeng/menubar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { ObjectExtensionsComponent } from "./components/extension/object-extensions/object-extensions.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { ChipsModule } from "primeng/chips";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { PasswordModule } from "primeng/password";
import { MontantPipe } from "./pipes/montant.pipe";
import { NotificationComponent } from "./components/workflow/notification/notification.component";
import { BadgeModule } from "primeng/badge";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ToolbarModule } from "primeng/toolbar";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { AvatarModule } from "primeng/avatar";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ConfirmDialogModule } from "primeng/confirmdialog";

@NgModule({
  declarations: [
    CodificationComponent,
    ExtensionComponent,
    PaysComponent,
    WorkflowComponent,
    ObjectStateComponent,
    ObjectExtensionsComponent,
    NotificationComponent,
    MontantPipe,
    TruncatePipe,
  ],
  exports: [
    ObjectStateComponent,
    ObjectExtensionsComponent,
    NotificationComponent,
    MontantPipe,
  ],

  imports: [
    CommonModule,
    RouterModule,
    MenubarModule,
    FormsModule,
    ReactiveFormsModule,

    TabViewModule,
    TableModule,
    ButtonModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    AutoCompleteModule,
    CalendarModule,
    ChipsModule,
    CascadeSelectModule,
    DropdownModule,
    MultiSelectModule,
    PasswordModule,
    TagModule,
    SplitButtonModule,
    SpeedDialModule,
    TimelineModule,
    CardModule,
    DialogModule,
    SplitterModule,
    ChipModule,
    MultiSelectModule,
    DropdownModule,
    BadgeModule,
    OverlayPanelModule,
    ToolbarModule,
    AvatarModule,
    ConfirmPopupModule,
    ConfirmDialogModule
  ],
})
export class SettingModule {}
