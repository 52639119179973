import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ObjectStateComponent } from "src/app/modules/setting/components/workflow/object-state/object-state.component";
import {
  StatusView,
  StatusViewSeverity,
} from "src/app/modules/setting/models/codification-constants";
import { BreadcrumbService } from "src/app/modules/shared/components/breadcrumb.service";
import { Demande } from "../../models/demande";
import { DemandeConstants } from "../../models/demande-constants";
import { DemandeHabilitation } from "../../models/habilitation/demande-habilitation";
import { DemandeSoumissionService } from "../../services/demande-soumission.service";
import { DemandeService } from "../../services/demande.service";
import { PaiementStatusComponent } from "../paiement/paiement-status/paiement-status.component";

@Component({
  selector: "app-recapitulatif",
  templateUrl: "./recapitulatif.component.html",
  styleUrls: ["./recapitulatif.component.css"],
})
export class RecapitulatifComponent implements OnInit {
  demande: Demande;
  concept: string;
  fonction: string;
  userId: string;

  statutPieces: StatusView;
  statutPaiement: StatusView;

  @ViewChild("state") stateComponent: ObjectStateComponent;

  @ViewChild("statePaiement") statePaiementComponent: PaiementStatusComponent;

  constructor(
    public demandeSoumissionService: DemandeSoumissionService,
    private router: Router,
    private route: ActivatedRoute,
    private demandeService: DemandeService,
    private habilitationService: DemandeHabilitation,
    private breadcrumbService: BreadcrumbService
  ) {
    this.concept = DemandeConstants.CONCEPT_METIER_DEMANDE;
    this.fonction = DemandeConstants.FUNCTION_DEMANDE;
    this.userId = this.habilitationService.userId();
  }

  ngOnInit(): void {
    this.statutPieces = {} as StatusView;
    this.statutPieces.label = "Soumis";
    this.statutPieces.severity = StatusViewSeverity.info;

    this.route.queryParams.subscribe((params) => {
      const id = params.id;
      if (id != null) {
        this.demandeService.find(id).subscribe((data) => {
          if (!data || !data.id) {
            this.habilitationService.redirectHome();
          }
          this.demande = data;
          this.breadcrumbService.setItems([
            {
              label: "Demandes",
            },
            { label: this.demande.categorieDemande.typeDemande.libelle },
            { label: this.demande.reference },
            { label: "Détails" },
          ]);

          this.demandeSoumissionService.setDemande(data);
          // this.statePaiementComponent.demande = id;
        });
      }
    });
  }

  viewPiece() {
    // if (!this.demande.etat.modifiable) {
      this.router.navigateByUrl(
        DemandeConstants.ROUTE_DEMANDE_VIEW_PIECES + "?id=" + this.demande.id
      );
    // }
  }
  viewPaiement() {
    // if (!this.demande.etat.modifiable) {
      this.router.navigateByUrl(
        DemandeConstants.ROUTE_DEMANDE_VIEW_PAIEMENT + "?id=" + this.demande.id
      );
    // }
  }

  prevPage() {
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_CREATE_PAIEMENT + "?id=" + this.demande.id
    );
  }
  updateStatus(event) {
    this.demande.etat.initial = false;
  }
}
