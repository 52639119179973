import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { interval, Subscription } from "rxjs";
import { User } from "src/app/modules/security/models/user";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import {
  CodificationUrgence,
  StatusViewSeverity,
} from "../../../models/codification-constants";
import { ActionNotification, StepPermission } from "../../../models/workflow";
import { ActionService } from "../../../services/workflow/action.service";
import { WorkflowService } from "../../../services/workflow/workflow.service";
import { CacheService } from "src/app/modules/shared/services/cache.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() concept: string;


  @Input() fonction: string;

  @Input() icon: string = "pi pi-envelope";

  @Input() user: string;

  @Input() showNotificationUser: boolean = true;
  @Input() anonymousUser: string = "Anonymous";

  @Input() viewURL: string;

  // every 10 second
  @Input() refresh: number = 10000;

  @Output() view: EventEmitter<any> = new EventEmitter();

  notifications: ActionNotification[];
  selectedNotification: ActionNotification;

  subscription: Subscription;

  cacheKey: string;

  constructor(
    private logService: LogService,
    private notificationService: NotificationService,
    private workflowService: WorkflowService,
    private authService: AuthService,
    private cacheService: CacheService
  ) {
   
   }

  ngOnInit(): void {
    this.getNotification();
    this.logService.log("refreshNotification: " + this.refresh);
    //emit value in sequence every 10 second
    const source = interval(this.refresh);
    this.subscription = source.subscribe((val) => {
      this.logService.log("UPDATE NOTIFICATIONS START");
      this.getNotification();
      this.logService.log("UPDATE NOTIFICATIONS END");
    });
  }

  getNotification() {
    this.cacheKey = "notification" + this.concept;
    if (this.cacheService.has(this.cacheKey)) {
      this.notifications = this.cacheService.get(this.cacheKey) as ActionNotification[];
      this.logService.log("end get cache " + this.cacheKey);
      return;
    }

    if (this.authService.isLoggedIn()) {
      this.workflowService
        .ownNotification(this.concept, this.fonction)
        .subscribe((data) => {
          this.logService.log("set cache " + this.cacheKey);
          this.cacheService.set(this.cacheKey, data);
          this.notifications = data;
        });
    }
  }

  getNotificationSeverity(id: string): string {
    let severity = StatusViewSeverity.danger;
    if (id == CodificationUrgence.BASSE) {
      severity = StatusViewSeverity.info;
    } else if (id == CodificationUrgence.NORMALE) {
      severity = StatusViewSeverity.warning;
    }

    return severity;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onRowSelect(event) {
    this.logService.log("notification select");
    this.logService.log(this.selectedNotification);
    this.logService.log("notification select");
    this.view.emit(this.selectedNotification);
  }

  userAvatarName(user: any): string {
    let avatarName;
    if (user && user.firstName) {
      avatarName = user.firstName.charAt(0) + user.lastName.charAt(0);
    } else {
      avatarName = user.charAt(0);
    }
    return avatarName.toUpperCase();
  }
  userFullName(user: User): string {
    return user.firstName + " " + user.lastName;
  }
}
