import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Form, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { ListItem } from 'src/app/modules/setting/models/codification';
import { ListItemService } from 'src/app/modules/setting/services/codification/list-item.service';
import { BreadcrumbService } from 'src/app/modules/shared/components/breadcrumb.service';
import { FormService } from 'src/app/modules/shared/services/form.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';
import { Demande } from '../../models/demande';
import {
  CodificationModePaiement,
  CodificationOptionPaiement,
  DemandeConstants,
  TypePaiementConstants,
  TypeServiceConstants,
} from '../../models/demande-constants';
import { DemandeHabilitation } from '../../models/habilitation/demande-habilitation';
import { Paiement, TypePaiement } from '../../models/paiement';
import { Personne } from '../../models/personne';
import { PieceJointe } from '../../models/piece';
import { DemandeSoumissionService } from '../../services/demande-soumission.service';
import { DemandeService } from '../../services/demande.service';
import { PaiementService } from '../../services/paiement.service';
import { PieceJointeService } from '../../services/piece-jointe.service';
import { TranslateService } from "@ngx-translate/core";
import { environment } from 'src/environments/environment';
import { saveAs } from "file-saver";



@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.css'],
})
export class PaiementComponent implements OnInit {
  demande: Demande;
  pieces: PieceJointe[];
  paiements: Paiement[];

  typePaiements: TypePaiement[];
  modePaiements: ListItem[];

  selectedPaiement: Paiement;
  paiement: Paiement;
  optionPaiements: ListItem[];

  displayVisa: boolean;
  displayEspece: boolean;
  displayCheque: boolean;
  displayVirement: boolean;

  displayTransactionStatus: boolean;
  transactionStatus: any;

  paiementAcceptation: boolean;
  optionPaiement: string;

  concept: string;
  fonction: string;
  userId: string;

  isDemandePaid: boolean = false;

  transactionFields: any[] = [];

  ecobankCybersource: string;

  @ViewChild('form') form: Form;

  constructor(
    private notificationService: NotificationService,
    private demandeSoumissionService: DemandeSoumissionService,
    private httpClient: HttpClient,
    private paiementService: PaiementService,
    private router: Router,
    private route: ActivatedRoute,
    private listItemService: ListItemService,
    private demandeService: DemandeService,
    private formService: FormService,
    private habilitationService: DemandeHabilitation,
    private logService: LogService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService
  ) {
    this.concept = DemandeConstants.CONCEPT_METIER_PAIEMENT;
    this.fonction = DemandeConstants.FUNCTION_PAIEMENT;
    this.userId = this.habilitationService.userId();
    this.initPaiement();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const id = params.id;
      const transaction = params.t;
      const paiement = params.p;

      this.logService.log('PaiementComponent');
      this.logService.log(params);
      this.logService.log('PaiementComponent');

      if (paiement && transaction) {
        this.paiementService.find(paiement).subscribe((pay) => {
          if (pay && pay.demande && pay.demande.id) {
            this.getDemandeInit(pay.demande.id);
            this.isDemandeAlreadyPaid(id);

            this.paiementService
              .isTrasactionStatus(transaction)
              .subscribe((data) => {
                this.logService.log('isTrasactionStatus');
                this.logService.log(data);
                this.logService.log('isTrasactionStatus');

                this.transactionStatus = data;
                this.displayTransactionStatus = true;
              });
          }
        });
      } else {
        this.getDemandeInit(id);
        this.isDemandeAlreadyPaid(id);

      }
    });

    this.initParameters();
  }

  initPaiement() {
    this.ecobankCybersource = environment.ecobankCybersource;
    this.paiement = {} as Paiement;
    this.paiement.optionPaiement = {} as ListItem;
    this.paiement.personne = {} as Personne;
  }

  getDemandeInit(id) {
    if (id) {
      this.demandeService.find(id).subscribe((data) => {
        if (!data || !data.id) {
          this.habilitationService.redirectHome();
        }
        this.demande = data;
        this.breadcrumbService.setItems([
          {
            label: 'Demandes',
          },
          { label: this.demande.categorieDemande.typeDemande.libelle },

          { label: this.demande.reference },
          { label: 'Paiements' },
        ]);

        this.demandeSoumissionService.setDemande(data);
        this.getPaiements();
      });
    } else {
      this.getPaiements();
    }
  }

  isDemandeAlreadyPaid(id: string) {
    this.demandeService.isDemandePaid(id).subscribe(isPaid => {
      this.isDemandePaid = isPaid;
      this.logService.log("isDemandePaid: " + this.isDemandePaid);
      if (this.isDemandePaid) {
        this.notificationService.warningMessage("Cette demande est déjà payée!", "")
      }
    })
  }

  initParameters() {
    this.paiementService.listTypePaiements().subscribe((data) => {
      this.typePaiements = data;
    });

    this.listItemService
      .listByCodification(DemandeConstants.CODIFICATION_OPTION_PAIEMENT)
      .subscribe((data) => {
        this.optionPaiements = data;
        if (this.demande.categorieDemande.typeDemande.typeService) {
          if (
            this.demande.categorieDemande.typeDemande.typeService.code ==
            TypeServiceConstants.SURVATTER
          ) {
            this.optionPaiements = this.optionPaiements.filter(function (op) {
              return (
                op.id == CodificationOptionPaiement.TOTALITE
              );
            });
          }
        }
      });
  }

  getPaiements() {
    if (this.demande) {
      this.paiement.demande = this.demande;
      this.paiement.devise = this.demande.typeDemandeMontant.devise;
      this.optionPaiement = CodificationOptionPaiement.REDEVANCE;
      this.paiement.personne.id = this.habilitationService.userPersonneId();

      this.paiement.optionPaiement = {} as ListItem;
      if (
        this.demande.categorieDemande.typeDemande.typeService.code ==
        TypeServiceConstants.SURVATTER
      ) {
        this.optionPaiement = CodificationOptionPaiement.TOTALITE;
      }
      this.paiement.optionPaiement.id = this.optionPaiement;

      this.paiementService.listByDmde(this.demande.id).subscribe((data) => {
        if (data && data.length > 0) {
          this.logService.log('paiements');
          this.logService.log(data);

          this.logService.log('paiements');

          this.paiements = data;
          this.optionPaiement = CodificationOptionPaiement.RESTE;
          this.paiement.optionPaiement.id = this.optionPaiement;
        }
        this.getMontantAPayer();
        this.initParameters();
      });
    }
  }

  getOptionPaiement() {
    this.optionPaiements.forEach((o) => {
      if (o.id == this.optionPaiement) {
        this.paiement.optionPaiement = o;
        return;
      }
    });
  }
  getMontantAPayer() {
    this.getOptionPaiement();
    let montantPaye = 0;

    if (this.paiements && this.paiements.length > 0) {
      this.paiements.forEach((p) => {
        montantPaye = montantPaye + p.montant;
      });
    }
    if (
      this.paiement.optionPaiement.id == CodificationOptionPaiement.REDEVANCE
    ) {
      this.paiement.montant = this.demande.typeDemandeMontant.montantRedevance;
    } else if (
      this.paiement.optionPaiement.id ==
      CodificationOptionPaiement.EXONERATION ||
      this.paiement.optionPaiement.id == CodificationOptionPaiement.TOTALITE
    ) {
      this.paiement.montant = this.demande.typeDemandeMontant.montantTotal;
    } else {
      this.paiement.montant =
        this.demande.typeDemandeMontant.montantTotal - montantPaye;
    }

    if (this.paiement.montant < 0) {
      // this.notificationService.infoMessage('Tous les frais sont payés!');
      this.paiement.montant = 0;
    }
  }

  getPaiement() {
    this.optionPaiements.forEach((o) => {
      if (o.id == this.optionPaiement) {
        this.paiement.optionPaiement = o;
        return;
      }
    });
  }

  isPaiementExonore(): boolean {
    return (
      this.paiement.optionPaiement &&
      this.paiement.optionPaiement.id == CodificationOptionPaiement.EXONERATION
    );
  }

  isPaiementCarte(): boolean {
    return (
      this.paiement &&
      this.paiement.modePaiement &&
      this.paiement.modePaiement.id == CodificationModePaiement.CARTE_BANCAIRE
    );
  }

  initPaiementVisa() {
    this.displayVisa = true;
  }

  isPaiementMobile(): boolean {
    return (
      this.paiement &&
      this.paiement.modePaiement &&
      this.paiement.modePaiement.id == CodificationModePaiement.MOBILE_MONEY
    );
  }

  selectTypePaiement() {
    this.listItemService
      .listByCodification(DemandeConstants.CODIFICATION_MODE_PAIEMENT)
      .subscribe((data) => {
        this.modePaiements = data;
        if (
          this.paiement.typePaiement.id ==
          TypePaiementConstants.TYPE_PAIEMENT_COMPTANT
        ) {
          this.modePaiements = this.modePaiements.filter(
            (md) => md.id == CodificationModePaiement.ESPECE
          );
        }
        if (
          this.paiement.typePaiement.id ==
          TypePaiementConstants.TYPE_PAIEMENT_ONLINE
        ) {
          this.modePaiements = this.modePaiements.filter(
            (md) => md.id == CodificationModePaiement.CARTE_BANCAIRE
          );
        }

        if (this.modePaiements.length == 1) {
          this.paiement.modePaiement = this.modePaiements[0];
          this.selectModePaiement();
        }
      });
  }

  selectModePaiement() {
    this.logService.log('selectModePaiement');
    this.logService.log(this.paiement.modePaiement);
    this.logService.log('selectModePaiement');

    this.getMontantAPayer();
    if (this.paiement.modePaiement.id == CodificationModePaiement.ESPECE) {
      this.displayEspece = true;
    } else if (
      this.paiement.modePaiement.id == CodificationModePaiement.CHEQUE
    ) {
      this.displayCheque = true;
    } else if (
      this.paiement.modePaiement.id == CodificationModePaiement.VIREMENT
    ) {
      this.displayVirement = true;
    }
  }

  initEcobankTransaction(paiement: Paiement) {
    this.paiementService.initEcobankPayement(paiement).subscribe((data) => {
      this.displayVisa = true;
      for (const [key, value] of Object.entries(data)) {
        this.transactionFields.push([key, value]);
      }
      this.logService.log(this.transactionFields);
    });
  }

  savePaiement(payform: NgForm) {
    if (this.paiement.montant <= 0) {
      this.router.navigateByUrl(
        DemandeConstants.ROUTE_DEMANDE_CREATE_RECAPITULATIF +
        '?id=' +
        this.demande.id
      );
    } else {
      if (!this.paiementAcceptation) {
        this.notificationService.warningMessage(
          this.translate.instant('DEMANDE.PAIEMENT.NOTIFICATION-WARNINGMESSAGE'),
          this.translate.instant('DEMANDE.PAIEMENT.WARNINGMESSAGE')

        );
      }
      if (this.formService.isNgFormValid(payform)) {
        this.paiementService.save(this.paiement).subscribe((data) => {
          if (data && data.id) {
            this.logService.log('paiementService.save');
            this.logService.log(data);
            this.logService.log('paiementService.save');

            if (this.isPaiementCarte()) {
              this.logService.log('isPaiementCarte isPaiementCarte ');
              this.initEcobankTransaction(data);
            } else {
              this.initPaiement();
              this.paiementAcceptation = false;
              this.getPaiements();
            }
          }
        });
      }
    }
  }

  nextPage() {
    if (this.paiements && this.paiements.length) {
      this.demandeSoumissionService.setPaiement(this.paiement);
      // if (this.seatInformation.class && this.seatInformation.seat && this.seatInformation.wagon) {
      //     this.ticketService.ticketInformation.seatInformation = this.seatInformation;
      this.router.navigateByUrl(
        DemandeConstants.ROUTE_DEMANDE_CREATE_RECAPITULATIF +
        '?id=' +
        this.demande.id
      );
    }
  }

  prevPage() {
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_CREATE_PIECES + '?id=' + this.demande.id
    );
  }

  paiementFacture(paiement: Paiement) {
    // this.paiementService.printRecu(paiement);
    this.paiementService
      .downloadRecu(paiement.id, true)
      .subscribe((response) => {
        this.logService.log(response);
        // let downloadURL = window.URL.createObjectURL(new Blob(resultBlob.body, {type: "application/pdf"}));
        // window.open(downloadURL);
        var mediaType = "application/pdf";
        var blob = new Blob([response.body], { type: response.body.type });
        saveAs(blob, "RECU.pdf");
      });
    return;
  }
}
