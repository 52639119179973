import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { ExtensionService } from "src/app/modules/setting/services/extension/extension.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import {
  CategorieDemande,
  Demande,
  TypeDemandeMontant,
} from "../../models/demande";
import {
  DemandeConstants,
  GroupeExtensionConstants,
  TypeServiceConstants,
} from "../../models/demande-constants";
import { DemandeHabilitation } from "../../models/habilitation/demande-habilitation";
import { CategorieDemandeService } from "../../services/categorie-demande.service";
import { DemandeService } from "../../services/demande.service";
import { TypeDemandeMontantService } from "../../services/type-demande-montant.service";
import { TypeDemandeService } from "../../services/type-demande.service";
import { saveAs } from "file-saver";

const RECHERCHE_TOUS_DOC_LIVRES = "Toute autorisation/attestation";

@Component({
  selector: "app-list-demandes",
  templateUrl: "./list-dmde.component.html",
  styleUrls: ["./list-dmde.component.css"],
})
export class ListDmdeComponent implements OnInit {
  @ViewChild("dt") table: Table;

  @Input() typeService: string;
  @Input() typeDemande: string;

  @Input() editForm: boolean = false;
  @Input() editPaiement: boolean = false;
  @Input() processPiece: boolean = false;
  @Input() processChecklist: boolean = false;
  @Input() groupeExtension: string;

  motcle: string;
  motcleDocumentLivre: string;
  documentLivreRech: boolean;
  concept: string;
  objectId: string;
  userId: string;
  fonction: string;

  rechercheDocLivreOptions: string[] = [RECHERCHE_TOUS_DOC_LIVRES];

  params: any;
  totalRecords: number;
  loading: boolean;
  demandes: Demande[];

  categorieDemandes: CategorieDemande[];
  categorieDemande: string = null;

  typeDemandeMontants: TypeDemandeMontant[];
  typeDemandeMontant: string;

  typeDemandes: TypeDemandeService[];

  constructor(
    private router: Router,
    private logService: LogService,
    private notificationService: NotificationService,
    private habilitation: DemandeHabilitation,
    private demandeService: DemandeService,
    private typeDemandeService: TypeDemandeService,
    private categorieDemandeService: CategorieDemandeService,
    private typeDemandeMontantService: TypeDemandeMontantService,
    private extensionService: ExtensionService
  ) {
    this.concept = DemandeConstants.CONCEPT_METIER_DEMANDE;
    this.fonction = DemandeConstants.FUNCTION_DEMANDE;
    this.userId = this.habilitation.userId();
  }

  ngOnInit() {}

  ngOnChanges() {
    this.initParameters();
  }

  initParameters() {
    this.typeDemandeService.getAll(this.typeService).subscribe((data) => {
      this.typeDemandes = data;
    });

    this.categorieDemandeService
      .getAll(this.typeService, null)
      .subscribe((data) => {
        this.categorieDemandes = data;
      });
  }

  load(event: LazyLoadEvent) {
    this.loading = true;

    // in a real application, make a remote request to load data using state metadata from event
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Field name to sort with
    // event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    // filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    // imitate db connection over a network

    // let  page = (event.sortField == null ? event.first / event.rows : 0);
    const page = event.first / event.rows;
    const size = event.rows;
    this.params = {
      sort: event.sortField ? event.sortField : "reference",
      direction: event.sortOrder == 1 ? "DESC" : "ASC",
      motcle: event.globalFilter ? event.globalFilter : null,
    };

    if (this.motcle) {
      this.params.motcle = this.motcle;
    }
    if (this.typeDemande) {
      this.params.typeDemande = this.typeDemande;
    }
    if (this.typeService) {
      this.params.typeService = this.typeService;
    }

    if (this.categorieDemande) {
      this.params.categorie = this.categorieDemande;
    }
    if (this.typeDemandeMontant) {
      this.params.tdMontant = this.typeDemandeMontant;
    }

    if (this.motcleDocumentLivre != null) {
      this.documentLivreRech = true;
      this.params.documentLivre = this.documentLivreRech;
      if (this.motcleDocumentLivre != RECHERCHE_TOUS_DOC_LIVRES) {
        this.params.motcleDocumentLivre = this.motcleDocumentLivre;
      }
    }

    this.params.personne = this.habilitation.userPersonneId();

    this.demandeService.search(page, size, this.params).subscribe((data) => {
      if (data && data.content) {
        this.demandes = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
      } else {
        this.notificationService.errorMessage(null, null);
      }
    });
    this.loading = false;
  }
  resetFilters() {
    this.typeDemande = null;
    this.categorieDemande = null;
    this.typeDemandeMontant = null;
    this.typeDemandeMontants = [];
    this.motcle = null;
    this.motcleDocumentLivre = null;
  }
  selectCategorie() {
    this.typeDemandeMontantService
      .getAllByCategorie(this.categorieDemande)
      .subscribe((data) => {
        this.typeDemandeMontants = data;
      });
  }

  detail(demande: Demande) {
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_VIEW_RECAPITULATIF + "?id=" + demande.id
    );
  }
  create() {
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_CREATE_INFOS + "?ts=" + this.typeService
    );
  }

  view(demande: Demande, editable = false) {
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_VIEW_INFOS + "?id=" + demande.id
    );
  }

  payer(demande: Demande) {
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_VIEW_PAIEMENT + "?id=" + demande.id
    );
  }

  piece(demande: Demande) {
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_VIEW_PIECES + "?id=" + demande.id
    );
  }

  downloadDocumentLivre(demande: Demande) {
    this.logService.log("downloadDocumentLivre: " + demande.id);
    // if (demande.documentLivre && demande.documentLivre.document) {
      this.demandeService
        .downloadDocumentDelivre(demande.id, this.typeService)
        .subscribe((response) => {
          this.logService.log(response);
          // let downloadURL = window.URL.createObjectURL(new Blob(resultBlob.body, {type: "application/pdf"}));
          // window.open(downloadURL);
          var mediaType = "application/pdf";
          var filename = "Autorisation accordée";
          if(this.typeService == TypeServiceConstants.PEL) filename= "Licence accordée"
          var blob = new Blob([response.body], { type: response.body.type });
          saveAs(blob, filename + ".pdf");
        });
      return;
    // }

    // this.printDoc(demande);
  }

  printDoc(demande: Demande) {
    this.logService.log("printDoc");
    this.logService.log(demande);
    this.logService.log("printDoc");

    if (
      demande.categorieDemande.typeDemande.typeService.id ==
      TypeServiceConstants.SURVATTER
    ) {
      this.extensionService
        .listValeurExtensions(
          demande.id,
          GroupeExtensionConstants.GROUPE_EXTENSION_SURVATTER
        )
        .subscribe((data) => {
          this.demandeService.printAttestationSurvatter(demande, data);
        });
    } else if (
      demande.categorieDemande.typeDemande.typeService.id ==
      TypeServiceConstants.PEL
    ) {
      //Generate licen PEL
    }
  }

  formulaireFeedback(demande: Demande) {
    this.router.navigateByUrl(
      DemandeConstants.ROUTE_DEMANDE_FORM_FEEDBACK + "?id=" + demande.id
    );
  }
}
