import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgForm, ValidationErrors } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { LogService } from './log.service';
import { NotificationService } from './notification.service';


@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(private notificationService: NotificationService,
              private logService: LogService) { }

  isNgFormValid(ngForm: NgForm): any {
    if (ngForm.invalid) {
      let messageDetails = '';

      Object.keys(ngForm.form.controls).forEach(key => {

        const controlErrors: ValidationErrors = ngForm.form.get(key).errors;
        this.logService.log(controlErrors);

        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            if (keyError == 'required' && controlErrors[keyError]) {
              messageDetails = ' Ce champ  est  obligatoire';
              this.notificationService.errorMessage(key, messageDetails);

            } else
              if (keyError == 'email' && controlErrors[keyError]) {
                messageDetails = ' La valeur du champ  n\'est pas un email valide';
                this.notificationService.errorMessage(key, messageDetails);

              } else
                if (keyError == 'pattern' && controlErrors[keyError] && controlErrors[keyError].requiredPattern) {
                  messageDetails = ' La valeur du champ ne respecte pas le schéma ' + controlErrors[keyError].requiredPattern;
                  this.notificationService.errorMessage(key, messageDetails);
                } else
                  if (keyError == 'minlength' && controlErrors[keyError] && controlErrors[keyError].requiredLength) {
                    messageDetails = ' La valeur du champ  doit avoir une longueur minimale de ' + controlErrors[keyError].requiredLength;
                    this.notificationService.errorMessage(key, messageDetails);

                  } else
                    if (keyError == 'maxlength' && controlErrors[keyError] && controlErrors[keyError].requiredLength) {
                      messageDetails = ' La valeur du champ  doit avoir une longueur maximale de ' + controlErrors[keyError].requiredLength;
                      this.notificationService.errorMessage(key, messageDetails);
                    } else {
                      messageDetails = ' La valeur du champ n\' est pas valide ' + controlErrors[keyError];
                      this.notificationService.errorMessage(key, messageDetails);
                    }

            this.logService.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ');
          });
        }
      });
      return false;

    }

    return true;
  }

}
