import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/modules/shared/services/crud.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { environment } from 'src/environments/environment';

const URL_EXTENSION_GROUPE: string = environment.apiUrl + '/setting/extension/groupe';

@Injectable({
  providedIn: 'root'
})
export class GroupeExtensionService extends CrudService {

  ressourceBaseURL(): string {
    return URL_EXTENSION_GROUPE;
  }

  constructor(public httpClient: HttpClient, public logService: LogService) {
    super(httpClient, logService);
  }
}
