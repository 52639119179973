import { Component, Input, OnInit } from "@angular/core";
import {
  StatusView,
  StatusViewSeverity,
} from "src/app/modules/setting/models/codification-constants";
import { LogService } from "src/app/modules/shared/services/log.service";
import { CodificationModePaiement } from "../../../models/demande-constants";
import { PaiementService } from "../../../services/paiement.service";

@Component({
  selector: "app-paiement-transaction",
  templateUrl: "./paiement-transaction.component.html",
  styleUrls: ["./paiement-transaction.component.css"],
})
export class PaiementTransactionComponent implements OnInit {
  status: StatusView;

  @Input() paiement: string;
  @Input() accepted: boolean = true;

  amount: number;

  constructor(private paiementService: PaiementService) {}

  ngOnInit(): void {}

  getTransactionStatus() {
    this.paiementService.find(this.paiement).subscribe((paiementObject) => {
      if (
        paiementObject &&
        paiementObject.modePaiement &&
        paiementObject.modePaiement.id ==
          CodificationModePaiement.CARTE_BANCAIRE
      ) {
        this.paiementService
          .transactionAmount(paiementObject.id, this.accepted)
          .subscribe((amount) => {
            this.status = {} as StatusView;
            this.status.label = amount + "";

            if (amount > 0 && amount >= paiementObject.montant) {
              this.status.severity = StatusViewSeverity.success;
            } else {
              this.status.severity = StatusViewSeverity.danger;
            }
          });
      }
    });
  }

  ngOnChanges() {
    this.getTransactionStatus();
  }
}
