import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/modules/security/models/user';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { FormService } from 'src/app/modules/shared/services/form.service';
import { LogService } from 'src/app/modules/shared/services/log.service';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';
import { CompteConstants } from '../../../models/compte-constants';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css'],
})
export class RecoveryComponent implements OnInit {
  utilisateur: User = new User();

  constructor(
    private logService: LogService,
    private authService: AuthService,
    private formService: FormService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router

  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const key = params[`key`];

      if (key != null) {
        this.authService.getUserByResetKey(key).subscribe((user) => {
          if (user) {
            this.utilisateur = user;
          } else {
            this.router.navigateByUrl(CompteConstants.ROUTE_LOGIN);
          }
        });
      }
    });
  }

  resetPassword(recform: NgForm) {
    this.logService.log(recform);
    if (this.formService.isNgFormValid(recform)) {
      if (this.utilisateur.password != this.utilisateur.confirmPassword) {
        this.notificationService.errorMessage(
          'Confirmation de mot de passe est incorrect',
          'Veuillez confirmer votre mot de passe!'
        );
        return;
      }

      this.authService.resetpassword(this.utilisateur).subscribe((data) => {
        if (data) {
          this.notificationService.successMessage();
          setTimeout(() => {
            this.authService.logout();
          }, 2000);
        } else {
          // something went wrong
          this.notificationService.serverError();
        }
      });
    }
  }
}
