import { ListItem } from 'src/app/modules/setting/models/codification';
import { Step } from 'src/app/modules/setting/models/workflow';
import { CategorieDemande, Demande } from './demande';

export interface PieceRequis {
    id: string;
    code: string;
    libelle: string;
    categorieDemande: CategorieDemande;
    piece: ListItem;
}

export interface PieceJointe {
    id: string;
    pieceRequis: PieceRequis;
    demande: Demande;
    document: Document;
    valide: boolean;
    conform:boolean;
    file: File;
    etat: Step;
}

export interface Document {
    id:          string;
    code:        string;
    titre:       string;
    description: string;
    reference:   string;
    path:        string;
    filename:    string;
    content:     any;
}


export enum FileContentType {
	PDF= 'application/pdf', IMAGE= 'image/',
}

