import { HabilitationService } from 'src/app/modules/shared/services/habilitation.service';

export class CompteConstants    {
	static ROUTE_LOGIN = '/login';
	static ROUTE_ACTIVATION = '/activation';
	static ROUTE_FORGET_PASSWORD = '/forget';
	static ROUTE_RESET_PASSWORD = '/reset-password';
	static ROUTE_CREATE_COMPTE = '/creer-compte';
	static ROUTE_MON_COMPTE = '/accueil/mon-compte';



	static REFERENTIEL_DEMANDEUR_ID = 'REF_PERS_DEMANDEUR';
	static PROFIL_USER_DEMANDEUR_ID = 'PROFIL_DEMANDEUR';
}
