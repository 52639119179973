import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  CodificationSurvatterType,
  ExtensionSurvatterConstants,
  GroupeExtensionConstants,
} from "src/app/modules/gas/demande/models/demande-constants";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { LogService } from "src/app/modules/shared/services/log.service";
import { NotificationService } from "src/app/modules/shared/services/notification.service";
import { CodificationTypeDonnee } from "../../../models/codification-constants";
import {
  Extension,
  GroupeExtension,
  ValeurExtension,
} from "../../../models/extension";
import { ListItemService } from "../../../services/codification/list-item.service";
import { ExtensionService } from "../../../services/extension/extension.service";
import { GroupeExtensionService } from "../../../services/extension/groupe-extension.service";
import { ExtensionUtils } from "../../../models/extension-utils";

@Component({
  selector: "app-object-extensions",
  templateUrl: "./object-extensions.component.html",
  styleUrls: ["./object-extensions.component.css"],
})
export class ObjectExtensionsComponent implements OnInit, OnChanges {
  @Input() concept: string;

  @Input() object: string;

  @Input() gExtension: string;

  @Input() editable = true;

  @Input() saveLabel = "Enregistrer";

  @Input() showAction = true;

  @Input() dateControl = true;

  // @Input() callback: Function;

  // @Input() public callbackParams: any;

  @Output() afterSave: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();


  valeurExtensions: ValeurExtension[];

  groupeExtension: GroupeExtension;

  constructor(
    private logService: LogService,
    private notificationService: NotificationService,
    private groupeExtensionService: GroupeExtensionService,
    private extensionService: ExtensionService,
    private listItemService: ListItemService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.logService.log("ObjectExtensionsComponent");
    this.logService.log(this.concept);
    this.logService.log(this.object);
    this.logService.log(this.gExtension);
    this.logService.log(this.editable);
    this.logService.log("ObjectExtensionsComponent");

    if (this.gExtension) {
      this.groupeExtensionService.find(this.gExtension).subscribe((data) => {
        this.logService.log("groupeExtensionService");
        this.logService.log(data);
        this.logService.log("groupeExtensionService");

        this.groupeExtension = data;
      });

      this.extensionService
        .listValeurExtensions(this.object, this.gExtension)
        .subscribe((data) => {
          this.logService.log("valeurExtensions");
          this.logService.log(data);
          this.logService.log("valeurExtensions");
          this.valeurExtensions = data;
          this.valeurExtensions.forEach((v) => {
            if (ExtensionUtils.isDateTime(v.extension) || ExtensionUtils.isDate(v.extension)) {
              if (v.valeur) {
                v.valeurDate = new Date(v.valeur);
              }
            }
            // SPECIFIC ACTION
            if (this.editable && !this.object) {
              if (
                v.extension.id == ExtensionSurvatterConstants.SURVATTER_APPL
              ) {
                v.valeur = this.authService.getUserFullName();
                // let adresse = this.authService.getUserPersonneAdresse();
                // if (adresse) {
                //   v.valeur = v.valeur + ", " + adresse;
                // }
              }
            }
            // SPECIFIC ACTION
          });
        });
    } else {
      this.notificationService.serverError("Extensions not found");
    }
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    // Write your code here
    this.logService.log("###DATA CHANGE");
    this.logService.log(this.object);
    this.updateValeurExtensions();
    this.logService.log("###DATA CHANGE");
  }

  hasChange(vext: ValeurExtension){
    this.logService.log("hasChange");
    this.logService.log(vext);

    this.change.emit(vext);
    
    this.logService.log("hasChange");

  }

  updateValeurExtensions() {
    this.extensionService
      .listValeurExtensions(this.object, this.gExtension)
      .subscribe((data) => {
        this.logService.log("updateValeurExtensions START");
        this.logService.log(data);
        this.valeurExtensions = data;
        this.valeurExtensions.forEach((v) => {
          if (ExtensionUtils.isDateTime(v.extension) || ExtensionUtils.isDate(v.extension)) {
            if (v.valeur) {
              v.valeurDate = new Date(v.valeur);
            }
          }
        });
        this.logService.log("updateValeurExtensions END");
      });
  }

  setValeurExtension(idExtension: string, value: any) {
    this.valeurExtensions.forEach((v) => {
      this.logService.log(
        "setValeurExtension idExtension " + idExtension + " - " + value
      );
      if (this.editable && !this.object) {
        if (v.extension.id == idExtension) {
          v.valeur = value;
        }
      }
    });
  }

  checkValeurExtensionPresent(val: ValeurExtension): boolean {
    if (
      val.extension.requis &&
      !val.valeur &&
      !val.valeurDate &&
      !val.valeurItem
    ) {
      this.notificationService.errorMessage(
        val.extension.propriete.caption,
        "Veuillez renseigner ce champ!"
      );
      return false;
    }

    if (
      ExtensionUtils.isAlphaNumeric(val.extension) ||
      ExtensionUtils.isNumeric(val.extension) ||
      ExtensionUtils.isTextArea(val.extension)
    ) {
      return !val.valeur || (val.valeur != null && val.valeur != "");
    } else if (ExtensionUtils.isDate(val.extension) || ExtensionUtils.isDateTime(val.extension)) {
      return (
        !val.valeurDate ||
        (val.valeurDate != null &&
          this.checkDate(val.valeurDate, val.extension.propriete.caption))
      );
    } else if (ExtensionUtils.isSelect(val.extension)) {
      return (
        !val.valeurItem || (val.valeurItem != null && val.valeurItem.id != null)
      );
    } else if (ExtensionUtils.isMultiSelect(val.extension)) {
      return (
        !val.valeurMultipleItems ||
        (val.valeurMultipleItems != null && val.valeurMultipleItems.length > 0)
      );
    }
    return false;
  }

  checkValeurExtensions(): boolean {
    let checked = true;
    this.valeurExtensions.forEach((val) => {
      // if (val.extension.requis) {
      if (!this.checkValeurExtensionPresent(val)) {
        checked = false;
      }
      // }
    });
    return checked && this.checkSurvatterValeurExtensions();
  }
  /**
   * Controle des champs obligatoires pour Survol et Atterissage
   * @returns
   */
  checkSurvatterValeurExtensions(): boolean {
    let checked = true;
    this.logService.log("checkSurvatterValeurExtensions");
    this.logService.log(this.gExtension);

    if (
      this.gExtension == GroupeExtensionConstants.GROUPE_EXTENSION_SURVATTER
    ) {
      const vExtType = this.valeurExtensions.filter(
        (vext) =>
          vext.extension.id == ExtensionSurvatterConstants.SURVATTER_TYPE
      )[0];
      this.logService.log(vExtType);

      if (
        vExtType.valeurItem &&
        vExtType.valeurItem.id == CodificationSurvatterType.SURVOL_ATTERISSAGE
      ) {
        const vExtDateEscale = this.valeurExtensions.filter(
          (vext) =>
            vext.extension.id ==
            ExtensionSurvatterConstants.SURVATTER_DATEESCALE
        )[0];
        this.logService.log(vExtDateEscale);

        if (!vExtDateEscale || !vExtDateEscale.valeurDate) {
          this.notificationService.errorMessage(
            vExtDateEscale.extension.propriete.caption,
            "doit être renseigner "
          );
          checked = false;
        }
        const vExtAssitanceEscale = this.valeurExtensions.filter(
          (vext) =>
            vext.extension.id ==
            ExtensionSurvatterConstants.SURVATTER_ASS_ESCALE
        )[0];
        this.logService.log(vExtAssitanceEscale);

        if (!vExtAssitanceEscale || !vExtAssitanceEscale.valeur) {
          this.notificationService.errorMessage(
            vExtAssitanceEscale.extension.propriete.caption,
            "doit être renseigner "
          );
          checked = false;
        }
        const vExtPersAContacter = this.valeurExtensions.filter(
          (vext) =>
            vext.extension.id ==
            ExtensionSurvatterConstants.SURVATTER_PERSACONTACTER
        )[0];
        this.logService.log(vExtPersAContacter);

        if (!vExtPersAContacter || !vExtPersAContacter.valeur) {
          this.notificationService.errorMessage(
            vExtPersAContacter.extension.propriete.caption,
            "doit être renseigner "
          );
          checked = false;
        }
        const vExtMedecin = this.valeurExtensions.filter(
          (vext) =>
            vext.extension.id == ExtensionSurvatterConstants.SURVATTER_MEDECIN
        )[0];
        this.logService.log(vExtMedecin);

        if (!vExtMedecin || !vExtMedecin.valeur) {
          this.notificationService.errorMessage(
            vExtMedecin.extension.propriete.caption,
            "doit être renseigner "
          );
          checked = false;
        }
      }
    }
    this.logService.log("checkSurvatterValeurExtensions - " + checked);

    return checked;
  }

  saveValeurExtensions(object?: string): boolean {
    this.logService.log("saveValeurExtensions START" + object);
    const checked = this.checkValeurExtensions();
    let idObject: string = this.object;
    if (object) {
      idObject = object;
    }

    if (checked) {
      this.logService.log("saveValeurExtensions idObject" + idObject);

      this.extensionService
        .saveValeurExtensions(idObject, this.valeurExtensions)
        .subscribe((data) => {
          if (data) {
            this.logService.log("event callback START");
            this.afterSave.emit(null);
            this.logService.log("event callback END");
          } else {
            this.notificationService.serverError();
          }
        });
    }
    this.logService.log("saveValeurExtensions END");

    // this.logService.log(this.callback);
    // this.logService.log(this.callbackParams);

    // this.callback(this.callbackParams);

    return checked;
  }

  setObject(object: string) {
    this.object = object;
  }

  checkDate(date, caption): boolean {
    const today = new Date();
    if (
      this.dateControl &&
      date.toLocaleDateString() != today.toLocaleDateString() &&
      date < today
    ) {
      this.notificationService.errorMessage(
        caption,
        "Doit être superieure à la date d'aujourd'hui "
      );
      return false;
    }
    return true;
  }
  onDateSelect(date, caption) {
    this.checkDate(date, caption);
  }

  trimText(value:string): string {
    if(!value) return;
    return (value.startsWith(' ') || value.endsWith('  '))? value.trim():value;
  }

  isNumeric(extension: Extension): boolean {
    return ExtensionUtils.isNumeric(extension);
  }

  isAlphaNumeric(extension: Extension): boolean {
    return ExtensionUtils.isAlphaNumeric(extension);
  }
  isTextArea(extension: Extension): boolean {
    return ExtensionUtils.isTextArea(extension);
  }
  isDateTime(extension: Extension): boolean {
    return ExtensionUtils.isDateTime(extension);
  }

  isDate(extension: Extension): boolean {
    return ExtensionUtils.isDate(extension);
  }
  isSelect(extension: Extension): boolean {
    return ExtensionUtils.isSelect(extension);
  }

  isMultiSelect(extension: Extension): boolean {
    return ExtensionUtils.isMultiSelect(extension);
  }

  isTreeSelect(extension: Extension): boolean {
    return extension.typeDonnee.id == CodificationTypeDonnee.CLASSIFICATION;
  }

  isTagSelect(extension: Extension): boolean {
    return ExtensionUtils.isTagSelect(extension);
  }
}
