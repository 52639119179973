import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/modules/shared/components/breadcrumb.service';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit {
  concept = 'DEMANDE';
  object = 'dssfsd';

  user = 'amah.tata';


  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Paramétrage' },
      { label: 'Workflow', routerLink: ['/setting/workflow'] }
    ]);
  }

  ngOnInit(): void {
  }

}
