import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { CompteConstants } from "../../gas/compte/models/compte-constants";
import { DemandeHabilitation } from "../../gas/demande/models/habilitation/demande-habilitation";
import { ProfilUtilisteur } from "../../gas/demande/models/demande-constants";
import { LogService } from "./log.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private logService: LogService,
    private authService: AuthService,
    private demandeHabilitation: DemandeHabilitation
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (
      this.authService.isLoggedIn() &&
      this.demandeHabilitation.hasProfil(ProfilUtilisteur.DEMANDEUR)
    ) {
      return true;
    }
    this.authService.setReturnUrl(state.url);
    this.authService.accessDenied();
    // this.router.navigateByUrl('/login')
    return false;
  }
}
